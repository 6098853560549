import { React, Container, useTimerSession } from "../../../common";
import { ContentLeft } from "../../components";
import ConcentComponent from "../../consents";
import { GetLoanDetailsById } from "../../../services/loanApi";

const Component = () => {

    const [initlized, setInitlized] = React.useState(false);
    const [row, setRow] = React.useState({});
    const [floanId] = useTimerSession("floanid");

    const FetchResults = async () => {
        global.Busy(true);
        let rslt = await GetLoanDetailsById(floanId);
        if (rslt.status === 100) {
            setRow(rslt.data);
        }

        global.Busy(false);
    };

    if (initlized) {
        setInitlized(false);
        setRow({});
        FetchResults();
    }

    React.useEffect(() => {
        setInitlized(true);
    }, []);


    return (
        <>
            <Container footerType={"full"} showRates={true}>
                <div className="widthfix">
                    <div id="leadsBlk">
                        <ul className="pgIIcol_leftNavi">
                            <li className="pgIIcol_leftNavi">
                                <ContentLeft form1003={true} id={row?.userid} loanNumber={row?.loanNumber} />
                            </li>
                            <ConcentComponent form1003={true} />
                        </ul>
                    </div>
                </div>
            </Container>
        </>
    );
};

export default Component;
