import { React, Helper, Link, Container, UploadHelper, useTimerSession, useNavigate } from "../../../../common";
import { ContentLeft } from "../../../components";
import { GetPreDocuments, AddPreDocument } from "../../../../services/preapprovalApi";
import { GetAssignDocTypes } from "../../../../services/documentApi";
import { GetConsentsResponse } from "../../../../services/consentsApi";


const maxsize = 106000000;
//const maxsize = 10600;
const formats = ["PNG", "JPG", "PDF"];

const Component = () => {
    const NavigateTo = useNavigate();
    const [initlized, setInitlized] = React.useState(false);
    const [ploanId] = useTimerSession("ploanid");
    const [puserId] = useTimerSession("puserid");
    const [ploanRef] = useTimerSession("ploanref");
    const [docList, setDocList] = React.useState([]);
    const [docTypes, setDocTypes] = React.useState([]);
    const [docTypeId, setDocTypeId] = React.useState("");

    const [row, setRow] = React.useState({});
    const [error, setError] = React.useState(null);
    const [fileurl, setFileUrl] = React.useState(null);
    const [formatError, setFormatError] = React.useState(null);
    const [hasAccess, setHasAccess] = React.useState(false);

    React.useEffect(() => {
        setInitlized(true);
    }, []);

    const OnInputChanged = (name, val) => {
        setError(null);
        setRow((prev) => ({
            ...prev,
            [name]: val,
        }));
    };

    const GetUserConcents = async () => {
        setHasAccess(false);
        global.Busy(true);
        let rslt = await GetConsentsResponse(ploanId);
        if (rslt.status === 100) {
            let _tmp = rslt.data || [];
            _tmp = _tmp.find((x) => x.requestype === 'UPLOAD')?.requestype;
            if (!Helper.IsNullValue(_tmp)) setHasAccess(true);
        }
        global.Busy(false);
    }

    const FetchPreResults = async () => {
        global.Busy(true);
        setDocList([]);
        setDocTypes([]);
        let rslt = await GetAssignDocTypes(ploanId);
        if (rslt.status === 100) {
            let _tmp = rslt.data.childs || [];
            if (_tmp.length > 0) _tmp = _tmp.filter((x) => x.checked);
            Helper.AddSelectOption(_tmp);
            _tmp[0].name = "Select";
            _tmp[0]._id = "";
            setDocTypes(_tmp);
        }

        await LoadDocuments();

        global.Busy(false);
    };

    const OnDocViewClicked = async (e, id) => {
        e.preventDefault();
        NavigateTo(`/leadv/${id}`);
    }

    const OnDocTypeChanged = (e) => {
        e.preventDefault();
        setDocTypeId(e.target.value);
    }

    if (initlized) {
        setInitlized(false);
        setDocTypeId("");
        setHasAccess(false);
        FetchPreResults();
    }

    const OnFileInputChanged = (e) => {
        setError(null);
        let _file = e.target.files[0];
        setFormatError(null);
        setFileUrl(null);
        if (!Helper.IsNullValue(_file)) {
            let _ext = _file.name.split(".").pop();
            let _index = formats.findIndex((x) => x === _ext.toUpperCase());
            if (_index > -1) {
                if (_file.size > maxsize) {
                    Helper.NativeInputClick("loandocs");
                    setFormatError("File size allowed: 100mb");
                } else {
                    setFileUrl(e.target.files[0]);
                }
            } else {
                Helper.NativeInputClick("loandocs");
                setFormatError("Supported Format: " + formats.join(","));
            }
        }
    };

    const OnFileUploadClicked = async (e) => {
        e.preventDefault();
        setError(null);

        if (Helper.IsJSONEmpty(row) || Helper.IsNullValue(fileurl)) {
            setError("All fields are mandatory");
            return;
        } else if (Helper.IsNullValue(row?.docname) || Helper.IsNullValue(row?.docinfo)) {
            setError("All fields are mandatory");
            return;
        }

        let _ext = fileurl.name.split(".").pop();
        let _tmp = docTypes.filter((x) => x._id === docTypeId)[0];
        let guid = Helper.GetGUID();
        let obj = {
            ...row,
            doctype: _tmp.name,
            docTypeId: docTypeId,
            mapid: ploanId,
            loannumber: ploanRef,
            ofileName: fileurl.name,
            req: {
                fileName: fileurl.name,
                predocs: true,
                blobName: `${ploanId}/${docTypeId}/${_tmp.name}/${guid}.${_ext}`
            }
        };

        let rslt = await UploadHelper.Upload(fileurl, obj);
        if (rslt.status !== 100) {
            Helper.NativeInputClick("loandocs");
            setError(rslt.statusText);
            return;
        }

        rslt = await AddPreDocument(obj);
        if (rslt.status !== 100) {
            Helper.NativeInputClick("loandocs");
            setError(rslt.statusText);
            return;
        }

        setRow({});
        setDocTypeId("");
        Helper.NativeInputClick("loandocs");
        await LoadDocuments();
    };

    React.useEffect(() => {
        setFormatError(null);
        setError(null);
    }, [docTypeId]);

    const LoadDocuments = async () => {
        setDocList([]);
        let data = [];
        let rslt = await GetPreDocuments(ploanId, 1);
        if (rslt.status === 100) data = rslt.data || [];
        await GetUserConcents();
        setDocList(data);
    }

    return (
        <>
            <Container footerType={"full"} showRates={true}>
                <div className="widthfix">
                    <div id="leadsBlk">
                        <ul className="pgIIcol_leftNavi">
                            <li className="pgIIcol_leftNavi">
                                <ContentLeft id={puserId} loanNumber={ploanRef} />
                            </li>
                            <li className="pgIIcol_leftNavi">
                                <div className="leadContentBlk">
                                    <div className="lead_headblk">
                                        <div className="blkName">Pre Docs List - {ploanRef}</div>

                                        <div className="head_right">
                                            <div className="rightVerBlock">
                                                <div className="filterSelectBox_80">
                                                    {/* <select name="filterType" defaultValue={'DEFAULT'} required >
                                                        <option value="DEFAULT" disabled>Version</option>
                                                        <option value="#">V1</option>
                                                        <option value="#">V2</option>
                                                    </select> */}
                                                </div>
                                            </div>
                                            <div className="rightVerBlock" style={{ display: "none" }}>
                                                <input type="button" className="rightButton" value="Upload Document" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="lead_filteredBlk" style={{ display: "none" }}>
                                        <div className="lead_filter"><b>Version:</b> V1<div className="fliter_close"><Link to="#" title="Close">&#215;</Link></div></div>
                                        <input type="button" className="lead_button_clear" value="Clear All" />
                                    </div>

                                    <div className="contentHeaderBlk">Upload New Documents for Lead ID: {ploanRef}</div>
                                    <div className="contentarea_pad10">
                                        <ul className="uploadPreDocSelect">
                                            <li className="uploadPreDocSelect">Document Type</li>
                                            <li className="uploadPreDocSelect">
                                                <div className="selectPreDocType">
                                                    <select
                                                        name="doctype"
                                                        disabled={!hasAccess}
                                                        value={Helper.ToString(docTypeId)}
                                                        onChange={(e) => OnDocTypeChanged(e)}>
                                                        {docTypes && docTypes.map((x, index) => (
                                                            <option value={x._id} key={index}>{x.name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </li>
                                            <li style={{ display: !Helper.IsNullValue(docTypeId) ? undefined : "none" }} className="uploadPreDocSelect">
                                                <ul className="uploadPreDocIIICol">
                                                    <li className="uploadPreDocIIICol">
                                                        <div className="leadContentHead">Document Name</div>
                                                        <div className="leadTxtField">
                                                            <input
                                                                type="text"
                                                                value={Helper.ToString(row.docname)}
                                                                onChange={(e) =>
                                                                    OnInputChanged(
                                                                        "docname",
                                                                        e.target.value
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </li>
                                                    <li className="uploadPreDocIIICol">
                                                        <div className="leadContentHead">Document Information</div>
                                                        <div className="leadTxtField">
                                                            <input
                                                                type="text"
                                                                value={Helper.ToString(row.docinfo)}
                                                                onChange={(e) =>
                                                                    OnInputChanged(
                                                                        "docinfo",
                                                                        e.target.value
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </li>
                                                    <li className="uploadPreDocIIICol">
                                                        <div className="leadContentHead">Document Upload</div>
                                                        <div className="leadContentHead_right">
                                                            {formatError && (<div className="fileFormat"> {formatError} </div>)}
                                                        </div>
                                                        <ul className="fileUpload">
                                                            <li className="uploadBox">
                                                                <input
                                                                    id="loandocs"
                                                                    type="file"
                                                                    name="loandocs"
                                                                    onClick={(e) => {
                                                                        e.target.value = null;
                                                                    }}
                                                                    onChange={(e) =>
                                                                        OnFileInputChanged(e)
                                                                    }
                                                                />
                                                            </li>
                                                            <li className="uploadButton">
                                                                <input
                                                                    type="submit"
                                                                    className="buttonUpload"
                                                                    value="Upload"
                                                                    onClick={(e) =>
                                                                        OnFileUploadClicked(e)
                                                                    }
                                                                />
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                    {error && (<div className="errormessage">{error}</div>)}
                                    <div className="contentarea_table">
                                        <div className="leadPreDocTable_head">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>Sl.</td>
                                                        <td>Document Type</td>
                                                        <td>Document Name</td>
                                                        <td>Document Info.</td>
                                                        <td>Ver</td>
                                                        <td>Uploaded By</td>
                                                        <td>Date</td>
                                                        <td>Time</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        {(!docList || !docList?.list || docList?.list.length === 0) && (<div className="noContent">No Documents Found</div>)}
                                        {!hasAccess && <div className="errormessage">You do not have access to upload the documents</div>}
                                        <div className="leadPreDocTable">
                                            <table>
                                                <tbody>
                                                    {docList && docList?.list && docList?.list.length > 0 && docList.list.map((x, index) => (
                                                        <tr key={x._id}>
                                                            <td>{index + 1}</td>
                                                            <td><Link to="#" onClick={(e) => OnDocViewClicked(e, index)}>{x.doctype}</Link></td>
                                                            <td>{x.docname}</td>
                                                            <td>{x.docinfo}</td>
                                                            <td>V1</td>
                                                            <td>{x.createdby}</td>
                                                            <td>{Helper.ToDateFormat(x.createdAt, 'YYY-MM-DD')}</td>
                                                            <td>{Helper.ToDateFormat(x.createdAt, 'HH:mm A')}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </Container>
        </>
    );
};

export default Component;
