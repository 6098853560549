import { React, useNavigate, Helper, TextInput } from "../../../common";
import { GetList } from "../../../services/enumsApi";
import { AddLoan, GetLoan, GetLoadIdByMappingId } from "../../../services/loanApi";
import Container from "./container1003";
import { RenderBorrowerInfo, RenderAddress, RenderEmployment, RenderIncomeSources } from "./childs";

const Component = ({ formId, approvalId, onTabClicked, noupdate }) => {
  const [initlized, setInitlized] = React.useState(false);
  const [errors, setErrors] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(null);
  const [loanId, setLoanId] = React.useState(null);
  const [row, setRow] = React.useState({});

  const [citizenshiptypes, setCitizenshipTypes] = React.useState([]);
  const [unittypes, setUnitTypes] = React.useState([]);
  const [housingtypes, setHousingtypes] = React.useState([]);
  const [sourcetypes, setSourcetypes] = React.useState([]);
  const [statesList, setStatesList] = React.useState([]);
  const [maritalStatusTypes, setMaritalStatusTypes] = React.useState([]);
  const [state, setState] = React.useState(false);
  const [lastStage, setLastStage] = React.useState(4);
  const stageIndicator = 4;
  const navigate = useNavigate();

  const FetchLookUps = async () => {
    return new Promise(async (resolve) => {
      global.Busy(true);
      let idList = [5, 7, 8, 18, 20, 21];
      let rslt = await GetList(idList);
      if (rslt.status === 100) {
        let t, _list;
        _list = rslt.data || [];
        t = _list.filter((x) => parseInt(x.type) === 5);
        Helper.AddSelectOption(t);
        setUnitTypes(t);
        t = _list.filter((x) => parseInt(x.type) === 7);
        Helper.AddSelectOption(t);
        setCitizenshipTypes(t);
        t = _list.filter((x) => parseInt(x.type) === 8);
        Helper.AddSelectOption(t);
        setHousingtypes(t);
        t = _list.filter((x) => parseInt(x.type) === 18);
        Helper.AddSelectOption(t);
        setStatesList(t);
        t = _list.filter((x) => parseInt(x.type) === 20);
        Helper.AddSelectOption(t);
        setMaritalStatusTypes(t);
        t = _list.filter((x) => parseInt(x.type) === 21);
        Helper.AddSelectOption(t);
        setSourcetypes(t);
      }

      global.Busy(false);
      return resolve(true);
    });
  };

  const FetchResults = async () => {
    setErrors([]);
    await FetchLookUps();
    global.Busy(true);
    setRow({});
    if (!Helper.IsNullValue(loanId)) {
      let rslt = await GetLoan(stageIndicator, loanId);
      if (rslt.status === 100) {
        let _data = rslt.data;
        if (_data && !Helper.IsJSONEmpty(_data)) {
          setRow(_data.stageFour);
          setLastStage(_data.stageIndicator);
        }
      }
    }
    global.Busy(false);
  };

  const GetCoBorrower = () => {
    return row?.coborrower || {};
  };

  const GetResidences = (appType, resType, mailIndicator) => {
    let ntmp = { applicantType: appType };
    if (resType) ntmp["residencyType"] = resType;
    if (mailIndicator) ntmp["mailingAddressIndicator"] = mailIndicator;
    let tmp = row?.residences?.filter((x) => x.applicantType === appType);
    if (tmp) {
      if (resType) {
        tmp = tmp.filter((x) => x.residencyType === resType);
        if (!Helper.IsArrayNull(tmp)) {
          return tmp[0];
        }
      } else if (mailIndicator) {
        tmp = tmp.filter((x) => x.mailingAddressIndicator === mailIndicator);
        if (!Helper.IsArrayNull(tmp)) {
          return tmp[0];
        }
      }
    }
    let res = row?.residences || [];
    res.push(ntmp);
    OnDirectInputChanged("residences", res);
    return ntmp;
  };

  const GetResErrIndex = (appType, resType, mailIndicator) => {
    let index = -1;
    let items = row?.residences || [];
    if (resType) {
      index = items.findIndex(
        (x) => x.applicantType === appType && x.residencyType === resType
      );
    } else if (mailIndicator) {
      index = items.findIndex(
        (x) =>
          x.applicantType === appType &&
          x.mailingAddressIndicator === mailIndicator
      );
    }
    return index;
  };

  const GetOtherIncomeSources = (owner) => {
    let ntmp = { delete: false, index: 0, owner: owner };
    let tmp = row?.otherIncomeSources?.filter((x) => x.owner === owner);
    if (!Helper.IsArrayNull(tmp)) {
      return tmp;
    }
    let res = row?.otherIncomeSources || [];
    res.push(ntmp);
    OnDirectInputChanged("otherIncomeSources", res);
    return [ntmp];
  };

  const GetEmployments = (owner, employmentTypeIndicator, addEmployment) => {
    let ntmp = { delete: false, index: 0, owner: owner };
    if (employmentTypeIndicator !== undefined)
      ntmp["employmentTypeIndicator"] = employmentTypeIndicator;
    if (employmentTypeIndicator === "Previous") {
      ntmp["currentEmploymentIndicator"] = false;
    } else {
      ntmp["currentEmploymentIndicator"] = true;
    }
    if (addEmployment !== undefined) ntmp["addEmployment"] = addEmployment;
    let tmp = row?.employment?.filter((x) => x.owner === owner);
    if (tmp) {
      if (employmentTypeIndicator !== undefined) {
        tmp = tmp.filter(
          (x) => x.employmentTypeIndicator === employmentTypeIndicator
        );
        if (!Helper.IsArrayNull(tmp)) {
          return tmp;
        }
      }
    }
    let res = row?.employment || [];
    res.push(ntmp);
    OnDirectInputChanged("employment", res);
    return [ntmp];
  };

  if (initlized) {
    setInitlized(false);
    setErrors([]);
    setError(null);
    setSuccess(null);
    FetchResults();
  }

  const OnDirectInputChanged = async (name, value) => {
    setRow((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const OnUpdateRecord = async (e, saved) => {
    e.preventDefault();
    setSuccess(null);
    if (errors && errors.length > 0) {
      setError("You have some errors. Please fix and proceed.")
      return;
    }
    setErrors([]);
    setError(null);
    setSuccess(null);
    row._id = loanId;
    row.stageIndicator = stageIndicator;
    global.Busy(true);
    let rslt = await AddLoan(row);
    global.Busy(false);
    if (rslt.status !== 100) {
      setErrors(rslt.errors);
      setError(rslt.statusText);
      return;
    } else {
      let _data = rslt.data;
      setRow(_data.stageFour);
    }
    if (saved) {
      setSuccess(Helper.SavedMessage);
    } else {
      navigate("/stage5");
    }
  };

  const OnAddArrayList = (name, json, options) => {
    let tmp = Helper.AddJsonArrayObject(row, name, json, options);
    OnDirectInputChanged(name, tmp);
  };

  const OnLocalInputChanged = async (parent, path, name, value) => {
    let options = { IsArray: false };
    let newRow = Helper.ModifyJsonArrayObject(row, path, value, options);
    setRow(newRow);
    setState(!state);
  };

  const OnInputChanged = async (path, value, options) => {
    let newRow = Helper.ModifyJsonArrayObject(row, path, value, options);
    setRow(newRow);
    setState(!state);
  };

  const OnBlurError = (name, val, clear) => {
    let _errors = [];
    if (errors && errors.length > 0) _errors = errors.filter((x) => x.field !== name);
    if (Helper.IsNullValue(clear)) _errors.push({ field: name, value: val });
    setErrors(_errors);
    setError(val);
  };

  const OnInputClicked = (name) => {
    let _err = null;
    if (errors && errors.length > 0) {
      let _findIndex = errors.findIndex((x) => x.field === name);
      if (_findIndex > -1) {
        _err = errors[_findIndex].value;
      }
    }
    setError(_err);
  };

  const OnLinkClicked = (a) => {
    if (onTabClicked) onTabClicked(a);
  }

  React.useEffect(() => {
    if (!Helper.IsNullValue(approvalId)) {
      const fn = async () => {
        let rslt = await GetLoadIdByMappingId(approvalId, 'pre');
        if (rslt.status === 100) {
          setLoanId(rslt.data._id);
        }
      };
      fn();
    }
  }, [approvalId]);

  React.useEffect(() => {
    if (!Helper.IsNullValue(formId)) {
      setLoanId(formId);
    }
  }, [formId]);

  React.useEffect(() => {
    if (!Helper.IsNullValue(loanId)) {
      setInitlized(true);
    }
  }, [loanId]);

  const MoveNextPrev = async (e, prev) => {
    e.preventDefault();
    if (prev) {
      OnLinkClicked(3);
    }
    else {
      OnLinkClicked(5);
    }
  };

  return (
    <>
      <Container selected={4} lastStage={lastStage} onTabClicked={OnLinkClicked}>
        <ul className="form1003IIcol">
          <li className="form1003IIcol">
            <div className="form1003BlkHead">
              <div className="formHeadContent">Co-Borrower Information</div>
              <div className="form1003Blk_right">&nbsp;</div>
            </div>
            <div className="form1003ContentBlk">
              <ul className="formContentIIcol">
                <li className="formContentIIcol">
                  <div className="form1003ContentHead">First Name</div>
                  <div className="formMandatory">*</div>
                  <div className="usericon"></div>
                  <TextInput
                    errors={errors}
                    path={"coborrower.firstName"}
                    tag={"coborrower.firstName"}
                    name={"firstName"}
                    value={Helper.ToString(row?.coborrower?.firstName)}
                    onInputChildChanged={OnLocalInputChanged}
                  />
                </li>
                <li className="formContentIIcol">
                  <div className="form1003ContentHead">Middle Name</div>
                  <div className="usericon"></div>
                  <TextInput
                    errors={errors}
                    tag={"coborrower.middleName"}
                    path={"coborrower.middleName"}
                    name={"middleName"}
                    value={Helper.ToString(row?.coborrower?.middleName)}
                    onInputChildChanged={OnLocalInputChanged}
                  />
                </li>
                <li className="formContentIIcol">
                  <div className="form1003ContentHead">Last Name</div>
                  <div className="formMandatory">*</div>
                  <div className="usericon"></div>
                  <TextInput
                    errors={errors}
                    path={"coborrower.lastName"}
                    tag={"coborrower.lastName"}
                    name={"lastName"}
                    value={Helper.ToString(row?.coborrower?.lastName)}
                    onInputChildChanged={OnLocalInputChanged}
                  />
                </li>
                <li className="formContentIIcol">
                  <div className="form1003ContentHead">Cell Phone</div>
                  <div className="formMandatory">*</div>
                  <div className="phicon"></div>
                  <TextInput
                    errors={errors}
                    maxLength={14}
                    tag={"coborrower.mobilePhone"}
                    path={"coborrower.mobilePhone"}
                    name={"mobilePhone"}
                    value={Helper.ToString(row?.coborrower?.mobilePhone)}
                    type={"PHONE"}
                    onInputChildChanged={OnLocalInputChanged}
                    onBlurError={OnBlurError}
                    onInputClicked={OnInputClicked}
                    required={true}
                  />
                </li>
              </ul>
              <div className="spacer_20margin_bottom">
                <div className="form1003ContentHead">Email Address</div>
                <div className="formMandatory">*</div>
                <div className="mailicon"></div>
                <TextInput
                  errors={errors}
                  tag={"coborrower.emailAddressText"}
                  path={"coborrower.emailAddressText"}
                  name={"emailAddressText"}
                  value={Helper.ToString(row?.coborrower?.emailAddressText)}
                  type={"EMAIL"}
                  onInputChildChanged={OnLocalInputChanged}
                  onBlurError={OnBlurError}
                  onInputClicked={OnInputClicked}
                  required={true}
                />
              </div>
              <RenderBorrowerInfo
                noheaders={true}
                tag={"coborrower"}
                item={GetCoBorrower()}
                maritalStatusTypes={maritalStatusTypes}
                citizenshiptypes={citizenshiptypes}
                onInputChanged={OnInputChanged}
                errors={errors}
                allowAdd={true}
                onBlurError={OnBlurError}
                onInputClicked={OnInputClicked}
                required={true}
              />
            </div>

            <RenderAddress
              tag={"residences"}
              item={GetResidences("CoBorrower", "Current")}
              statesList={statesList}
              unittypes={unittypes}
              housingtypes={housingtypes}
              onInputChanged={OnInputChanged}
              errors={errors}
              errorIndex={GetResErrIndex("CoBorrower", "Current")}
            />
            <RenderAddress
              tag={"residences"}
              item={GetResidences("CoBorrower", null, true)}
              citem={GetResidences("CoBorrower", "Current")}
              statesList={statesList}
              unittypes={unittypes}
              housingtypes={housingtypes}
              onInputChanged={OnInputChanged}
              errors={errors}
              errorIndex={GetResErrIndex("CoBorrower", null, true)}
            />
            <RenderEmployment
              items={{ childs: GetEmployments("CoBorrower", "Current", true) }}
              owner={"CoBorrower"}
              unittypes={unittypes}
              onInputChanged={OnInputChanged}
              statesList={statesList}
              allowAdd={true}
              allowDelete={true}
              onBlurError={OnBlurError}
              onInputClicked={OnInputClicked}
              required={true}
            />
          </li>
          <li className="form1003IIcol">
            <RenderEmployment
              items={{ childs: GetEmployments("CoBorrower", "Additional") }}
              owner={"CoBorrower"}
              unittypes={unittypes}
              onInputChanged={OnInputChanged}
              statesList={statesList}
              allowAdd={true}
              allowDelete={true}
              onBlurError={OnBlurError}
              onInputClicked={OnInputClicked}
              required={true}
            />
            <RenderEmployment
              items={{ childs: GetEmployments("CoBorrower", "Previous") }}
              owner={"CoBorrower"}
              unittypes={unittypes}
              onInputChanged={OnInputChanged}
              statesList={statesList}
              onAddArrayList={OnAddArrayList}
              allowAdd={true}
              allowDelete={true}
              onBlurError={OnBlurError}
              onInputClicked={OnInputClicked}
              required={true}
            />
            <RenderIncomeSources
              owner={"CoBorrower"}
              tag={"otherIncomeSources"}
              sourcetypes={sourcetypes}
              onInputChanged={OnInputChanged}
              items={{ childs: GetOtherIncomeSources("CoBorrower") }}
              onAddArrayList={OnAddArrayList}
              allowAdd={true}
              allowDelete={true}
            />
          </li>
        </ul>
        <ul className="form_btn_successError_blk">
          <li className="form_btn_steps_label">
            {noupdate && (
              <>
                <input
                  type="button"
                  defaultValue="Prev"
                  className="form_btn_steps"
                  onClick={(e) => MoveNextPrev(e, true)}
                />
                <input
                  type="button"
                  defaultValue="Next"
                  className="form_btn_steps"
                  onClick={(e) => MoveNextPrev(e)}
                />
              </>
            )}
            {!noupdate && (
              <>
                <input
                  type="button"
                  defaultValue="Save & Finish Later"
                  className="form_btn_steps"
                  onClick={(e) => OnUpdateRecord(e, true)}
                />
                <input
                  type="button"
                  defaultValue="Continue"
                  className="form_btn_steps"
                  onClick={(e) => OnUpdateRecord(e, false)}
                />
              </>
            )}
          </li>
          {error && (
            <li className="form_successError_steps">
              <div className="errormessage">{error}</div>
            </li>
          )}
          {success && (
            <li className="form_successError_steps">
              <div className="successmessage">{success}</div>
            </li>
          )}
        </ul>
      </Container>
    </>
  );
};

export default Component;
