import { React, Link, Container } from "../../common";

const Component = () => {
    return (
        <>
            <Container footerType={"full"} showRates={true}>
                <div className="widthfix_10">
                    <div id="contactBlk">
                        <div className="contactContentBlk">
                            <div className="contact_headblk">
                                <div className="blkName">Contact List</div>

                                <div className="head_right">
                                    <div className="rightVerBlock">
                                        <div className="documentTxtSearch"><input type="text" placeholder="Search by Name" /></div>
                                        <input type="button" className="searchicon" />
                                    </div>
                                </div>
                            </div>

                            <div className="contact_filteredBlk">
                                <div className="contact_filter"><b>Name:</b> Bhaskar<div className="fliter_close"><Link to="#" title="Close">&#215;</Link></div></div>
                                <input type="button" className="contact_button_clear" value="Clear All" />
                            </div>

                            <div className="contentarea_table" >
                                <div className="noContent" style={{ display: "none" }}>No contacts available</div>
                                <div className="contactListTable_head">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>Sl.</td>
                                                <td>Name</td>
                                                <td>Email ID</td>
                                                <td>Phone</td>
                                                <td>&nbsp;</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="contactListTable">
                                    <table>
                                        <tbody>

                                            <tr>
                                                <td>1</td>
                                                <td>Bhaskar NR</td>
                                                <td>bhaskar@uttarana.com</td>
                                                <td>(888) 975-1374</td>
                                                <td>&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    );
};

export default Component;
