import { React, Helper, Link, Container, Session, useNavigate } from "../../../common";
import { GetOfficersLoans, GetISPDocuments } from "../../../services/loanApi";

const Component = () => {
    const [initlized, setInitlized] = React.useState(false);
    const [rows, setRows] = React.useState([]);
    const [documents, setDocuments] = React.useState([]);
    const [row, setRow] = React.useState({});
    const [searchLoanNumber, setSearchLoanNumber] = React.useState('');
    const [searchClicked, setSearchClicked] = React.useState(false);
    const NavigateTo = useNavigate();

    const FetchResults = async () => {
        global.Busy(true);
        setRows([]);
        setDocuments([]);
        setRow({});
        let rslt = await GetOfficersLoans(searchLoanNumber);
        if (rslt.status === 100) {
            let tmp = rslt.data;
            if (tmp && tmp.length > 0) {
                setRows(tmp);
                setRow(tmp[0]);
                setSearchLoanNumber(tmp[0]?.loannumber)
                rslt = await GetISPDocuments(tmp[0].id, -100);
                if (rslt.status === 100) {
                    setDocuments(rslt.data);
                }
            }
        }
        global.Busy(false);
    };

    if (initlized) {
        setInitlized(false);
        setRows([]);
        Session.RemoveAsync('dloanid');
        Session.RemoveAsync('floanid');
        Session.RemoveAsync('ploanid');
        FetchResults();
    }

    const OnSearchClicked = (e) => {
        e.preventDefault();
        setSearchClicked(true);
        setRows([]);
        setDocuments([]);
        setRow({});
        if (!Helper.IsNullValue(searchLoanNumber)) FetchResults();
    }

    React.useEffect(() => {
        setInitlized(true);
    }, []);

    const OnDocViewClicked = async (e, id) => {
        e.preventDefault();
        Session.StoreAsync('dloanid', row.id);
        Session.StoreAsync('duserid', row.userid);
        Session.StoreAsync('dloannum', row.loannumber);
        NavigateTo(`/docview/${id}`);
    }

    const OnInputChanged = (e) => {
        e.preventDefault();
        setSearchLoanNumber(e.target.value);
        setSearchClicked(false);
    }

    return (
        <>
            <Container footerType={"full"} showRates={true}>
                <div className="widthfix_10">
                    <div id="documentBlk">
                        <div className="documentContentBlk">
                            <div className="document_headblk">
                                <div className="blkName">View Documents </div>
                                <div className="loanSearch">
                                    <input type="text" value={Helper.ToString(searchLoanNumber)} onChange={(e) => OnInputChanged(e)} placeholder="Search by Loan No" />
                                    <input type="button" className="loanSearchIcon" onClick={(e) => OnSearchClicked(e)} />
                                </div>
                                <div className="head_right" style={{ display: "none" }}>
                                    <div className="rightVerBlock">
                                        <div className="filterSelectBox_120">
                                            <select name="filterType" defaultValue={'DEFAULT'} required >
                                                <option value="DEFAULT" disabled>Uploaded By</option>
                                                <option value="#">i3Lending</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {searchClicked && !Helper.IsNullValue(searchLoanNumber) && rows && rows.length === 0 && (
                                <div className="contentarea_table" >
                                    <div className="noContent">No Loan found for {searchLoanNumber}</div>
                                </div>
                            )}

                            {rows && rows.length > 0 && (
                                <>
                                    <div className="document_headblk">
                                        <div className="blkName">Documents for Loan No - {row?.loannumber}</div>
                                        <div className="head_right">
                                            <div className="rightVerBlock" style={{ display: "none" }}>
                                                <div className="filterSelectBox_120">
                                                    <select name="filterType" defaultValue={'DEFAULT'} required >
                                                        <option value="DEFAULT" disabled>Loan Number</option>
                                                        <option value="#">0123456789</option>
                                                        <option value="#">2345678901</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <ul className="documentSummaryBlk">
                                        <li className="documentSummaryBlk">
                                            <div className="documentSummaryHead">Borrower Name:</div>
                                            <div className="documentSummaryText">{Helper.GetStringJoin(row, ['first_name', 'last_name'])}</div>
                                        </li>
                                        <li className="documentSummaryBlk">
                                            <div className="documentSummaryHead">Loan Amount:</div>
                                            <div className="documentSummaryText">{Helper.USCurrencyFormat(row?.loanamount)}</div>
                                        </li>
                                        <li className="documentSummaryBlk">
                                            <div className="documentSummaryHead">Issuing Authority:</div>
                                            <div className="documentSummaryText">{row?.issuingauth}</div>
                                        </li>
                                        <li className="documentSummaryBlk">
                                            <div className="documentSummaryHead">Est Close Date:</div>
                                            <div className="documentSummaryText">{Helper.ToDateFormat(row?.closingdate, "YYYY-MMM-DD")}</div>
                                        </li>
                                        <li className="documentSummaryBlk">
                                            <div className="documentSummaryHead">Property Address:</div>
                                            <div className="documentSummaryText">{row?.propaddress}</div>
                                        </li>
                                    </ul>

                                    <div className="document_filteredBlk" style={{ display: "none" }}>
                                        <div className="document_filter"><b>Loan Number:</b> 0123456789<div className="fliter_close"><Link to="#" title="Close">&#215;</Link></div></div>
                                        <div className="document_filter"><b>Version:</b> V1<div className="fliter_close"><Link to="#" title="Close">&#215;</Link></div></div>
                                        <input type="button" className="document_button_clear" value="Clear All" />
                                    </div>

                                    <div className="contentarea_table" >
                                        {documents && documents.length === 0 && (
                                            <div className="noContent">No Documents found</div>
                                        )}

                                        {documents && documents.length > 0 && (
                                            <>
                                                <div className="documentListTable_head">
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td>Sl.</td>
                                                                <td>Document Type</td>
                                                                <td>Document Name</td>
                                                                <td>Document Info</td>
                                                                <td>Version</td>
                                                                <td>Uploaded By</td>
                                                                <td>Date</td>
                                                                <td>Time</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="documentListTable">
                                                    <table>
                                                        <tbody>
                                                            {documents && documents.length > 0 && documents.map((x, index) => (
                                                                <tr key={x.keyIndex}>
                                                                    <td>{x.keyIndex}</td>
                                                                    <td><Link to="#" onClick={(e) => OnDocViewClicked(e, index)}>{x.doctype}</Link></td>
                                                                    <td>{x.docname}</td>
                                                                    <td>{x.docinfo}</td>
                                                                    <td>{x.version}</td>
                                                                    <td>{Helper.GetStringJoin(x, [
                                                                        "first_name",
                                                                        "last_name",
                                                                    ])}</td>
                                                                    <td>{Helper.ToDate(
                                                                        x.createdt,
                                                                        "YYYY-MMM-DD"
                                                                    )}</td>
                                                                    <td>{Helper.ToDate(
                                                                        x.createdt,
                                                                        "hh:mm a"
                                                                    )}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </Container>

        </>
    );
};

export default Component;
