import { React, useNavigate, Helper } from "../../common";
import { GetMortgageRates } from "../../services/loanApi";

const Component = () => {
    const Navigate = useNavigate();
    const OnRatesClicked = (e) => {
        e.preventDefault();
        Navigate("/mortgagerates");
    }

    const [initlized, setInitlized] = React.useState(false);
    const [rows, setRows] = React.useState([]);

    const FetchResults = async () => {
        global.Busy(true);
        let rslt = await GetMortgageRates();
        if (rslt.status === 100) {
            let _data = rslt.data;
            _data = _data.filter((x) => x.type.toLowerCase() === 'purchase');
            Helper.SortByNumber(_data, 'orderid');
            setRows(_data);
        }
        global.Busy(false);
    };

    if (initlized) {
        setInitlized(false);
        setRows([]);
        FetchResults();
    }

    React.useEffect(() => { setInitlized(true); }, []);

    return (
        <div>
            <div className="topRateTicker">
                {rows && rows.length > 0 && rows.slice(0, 4).map((x) => {
                    return (
                        <ul className="rateTickerVerBlk_30yr" key={x._id}>
                            <li className="rateTickerHead">{x.term} </li>
                            <li className="rateTickerSubHeadInt">Interest Rate -</li>
                            <li className="rateTickerTxtInt">{x.rate}%</li>
                            <li className="rateTickerSubHeadAPR">APR -</li>
                            <li className="rateTickerTxtAPR">{x.apr}%</li>
                        </ul>
                    )
                })}
                <input type="button" value="Rates" className="rateTickerButton" onClick={(e) => OnRatesClicked(e)} />
            </div>
        </div>
    )
}

export default Component;