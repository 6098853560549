import { React, Container } from "../../common";
import { LeadsList, PipeLinesList, Notifications, LoansAggregate, LoanFunding, LoanSummary } from "./childs";
import { AddLeadDialog } from "../popups";

const Component = () => {
  const [showAddLead, setShowAddLead] = React.useState(false);
  const [submitClicked, setSubmitClicked] = React.useState(false);

  const OnAddLeadClicked = async (e) => {
    e.preventDefault();
    setSubmitClicked(false);
    setShowAddLead(true);
  }

  const OnSubmitClicked = async (e) => {
    setShowAddLead(false);
    setSubmitClicked(e);
  };

  return (
    <>
      <Container footerType={"full"} showRates={true}>
        <div id="dashboardBlk">
          <LoanSummary />
          <div className="widthfix_10">
            <ul className="pgIIcol_40by60">
              <li className="pgIIcol_40by60">
                <ul className="pgIIcol">
                  <LoanFunding />
                  <LoansAggregate />
                </ul>
              </li>
              <Notifications />
            </ul>
            <ul className="pgIIcol">
              <LeadsList onAddLeadClicked={OnAddLeadClicked} submitClicked={submitClicked} />
              <PipeLinesList />
            </ul>
          </div>
        </div>

        <div className="widthfix_10"></div>
        <AddLeadDialog display={showAddLead} onSubmittedNewLead={(e) => OnSubmitClicked(true)} onCloseClicked={(e) => OnSubmitClicked(false)} />
      </Container>
    </>
  );
};

export default Component;
