import API from "./api";
import session from "../session";

const GetDocuments = async (loanId, typeId) => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`doc?l=${loanId}&t=${typeId}`, {
      headers: token,
    });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

const DeleteDocuments = async (docId) => {
  let token = await session.GetHeader();
  try {
    const res = await API.delete(`doc?d=${docId}`, { headers: token });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

const AddDocuments = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.post(`doc`, input, { headers: token });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

const SetConsent = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.post(`doc/setconsent`, input, { headers: token });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

const SetStatus = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.post(`doc/setstatus`, input, { headers: token });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

const GetDocTypes = async (typeid) => {
  let token = await session.GetHeader();
  let _typeid = typeid || 0;
  try {
    const res = await API.get(`doc/types/${_typeid}`, { headers: token });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

const GetSignedUrls = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.post(`doc/getsignedurls`, input, { headers: token });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

const GetDocumentUrl = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.post(`doc/getdocurl`, input, { headers: token });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

const MoveDocument = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.post("doc/movedoc", input, {
      headers: token,
    });
    return res.data;
  } catch (err) {
    console.log(err);
    return { statusText: err.message, status: 900 };
  }
};

const SubmitDocuments = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.post(`doc/submit`, input, { headers: token });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

const GetAccessList = async (loanId, typeId) => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`docaccess?l=${loanId}&t=${typeId}`, {
      headers: token,
    });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

const SetAccessList = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.post(`docaccess`, input, { headers: token });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

const SetAssignDocTypes = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.post(`pre/assigndoctypes`, input, { headers: token });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

const GetAssignDocTypes = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`pre/assigndoctypes/${input}`, { headers: token });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};



export {
  GetDocTypes,
  GetDocuments,
  DeleteDocuments,
  SubmitDocuments,
  GetAccessList,
  SetAccessList,
  GetSignedUrls,
  GetDocumentUrl,
  MoveDocument,
  AddDocuments,
  SetConsent,
  SetStatus,
  SetAssignDocTypes,
  GetAssignDocTypes
};
