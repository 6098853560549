import API from "./api";
import session from "../session";

const GetConsents = async () => {
    let token = await session.GetHeader();
    try {
        const res = await API.get(`consents`, { headers: token });
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const GetConsentsRequest = async (loanId, coborrowers, type) => {
    let token = await session.GetHeader();
    let req = {
        mapid: loanId,
        coborrower: coborrowers,
        requesttype: type
    };

    try {
        const res = await API.post(`consents/request/list`, req, { headers: token });
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const SetConsentsRequest = async (input) => {
    let token = await session.GetHeader();
    try {
        const res = await API.post(`consents/request`, input, { headers: token });
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const OtpValidateRequest = async (id, otp) => {
    let token = await session.GetHeader();
    try {
        const res = await API.get(`consents/validate/${id}/${otp}`, { headers: token });
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const GetConsentsResponse = async (id, type) => {
    let token = await session.GetHeader();
    try {
        const res = await API.get(`consents/request/${id}/${type}`, { headers: token });
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};


export {
    GetConsents,
    GetConsentsRequest,
    SetConsentsRequest,
    OtpValidateRequest,
    GetConsentsResponse
};
