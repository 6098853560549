import { React, Link } from "../../../common";

const Component = () => {
  return (
    <div className="dashboardSummary">
      <div className="widthfix_10">
        <ul className="dashVer5col">
          <li className="dashVer5col">
            <Link to="/lead" title="Leads">
              <div className="dash_semiCircle">
                <div className="dashSummaryIconBlk">
                  <div className="dashSummaryLeadIcon"></div>
                </div>
              </div>
              <div className="dash_summaryName">Total Leads</div>
              <div className="dash_summaryDesc">
                43
                <div className="noteTxt">5 New Leads</div>
              </div>
            </Link>
          </li>
          <li className="dashVer5col">
            <Link to="/pipe" title="Pipeline">
              <div className="dash_semiCircle">
                <div className="dashSummaryIconBlk">
                  <div className="dashSummaryPipelineIcon"></div>
                </div>
              </div>
              <div className="dash_summaryName">Lead to Customers</div>
              <div className="dash_summaryDesc">
                57%
                <div className="noteTxt">Conversion</div>
              </div>
            </Link>
          </li>
          <li className="dashVer5col">
            <Link to="/pipe" title="Pipeline">
              <div className="dash_semiCircle">
                <div className="dashSummaryIconBlk">
                  <div className="dashSummaryAmountIcon"></div>
                </div>
              </div>
              <div className="dash_summaryName">Total Loan Amount</div>
              <div className="dash_summaryDesc">$ 10,00,000</div>
            </Link>
          </li>
          <li className="dashVer5col">
            <Link to="/leads1003stepI" title="Application">
              <div className="dash_semiCircle">
                <div className="dashSummaryIconBlk">
                  <div className="dashSummaryCampaignIcon"></div>
                </div>
              </div>
              <div className="dash_summaryName">Application Status</div>
              <div className="dash_summaryDesc">
                35%
                <div className="noteTxt">Pending</div>
              </div>
            </Link>
          </li>
          <li className="dashVer5col">
            <Link to="/contacts" title="Customers">
              <div className="dash_semiCircle">
                <div className="dashSummaryIconBlk">
                  <div className="dashSummaryClosureIcon"></div>
                </div>
              </div>
              <div className="dash_summaryName">Average Closure</div>
              <div className="dash_summaryDesc">
                25
                <div className="noteTxt">Days</div>
              </div>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Component;
