// http://i3fintech.s3-website.ap-south-1.amazonaws.com
import React from "react";
import RendorRoutes from "./routes";

/* {
  "email": "ajayadavikatla14@gmail.com",
    "scope": "email",
      "apptype": "lend",
        "password": "123"
} */

const user = {
  name: "Camisha King Braden",
  role: 0,
  status: 1,
  typeindex: 4,
  entity: "Loan Officer",
  rolename: "Loan Officer",
  issuer: "i3lend lend",
  parentid: 393,
  approved: 0,
};

const userToken =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiNDM5IiwidHlwZWlkIjowLCJmaXJzdF9uYW1lIjoiQ2FtaXNoYSBLaW5nIiwibGFzdF9uYW1lIjoiQnJhZGVuIiwiY3JlYXRlZHQiOiIyMDIyLTEyLTAzVDAwOjAwOjAwLjAwMFoiLCJzdGF0dXNpZCI6MSwiaXNwaWQiOiI2MzhhZGQ1MGFhMzE2YzAwMjBjMTYwZGYiLCJwYXJlbnRpZCI6IjM5MyIsInR5cGVpbmRleCI6NCwiZGVmYXJvdXRlIjoibGQiLCJhcHByb3ZlZCI6MCwiZW1haWwiOiJjYW1pc2hhQGkzbGVuZGluZy5jb20ifSwiaWF0IjoxNjc1Nzc2NDgxLCJleHAiOjE2NzkzNzY0ODEsInN1YiI6IjQzOSJ9.Hx46LSeiyF2irq6cpdvzdC5G2NQrBP16JC_fwg4dAWc";

global.Busy = (bBusy) => {
  var x = document.getElementById("busyloader");
  if (x) {
    if (bBusy) {
      x.className = "loader display-block";
    } else {
      x.className = "loader display-none";
    }
  }
};

/* eslint-disable */
const PreToken = () => {
  sessionStorage.setItem("userToken", userToken);
  sessionStorage.setItem("user", JSON.stringify(user));
  sessionStorage.setItem("status", 1);
  /* sessionStorage.setItem("loanid", '63cb479b000efc424b547109'); */
};

const RemovePreToken = () => {
  sessionStorage.removeItem("userToken");
  sessionStorage.removeItem("user");
  sessionStorage.removeItem("status");
};
/* eslint-enable */

function App() {
  //PreToken();
  //RemovePreToken();
  return <RendorRoutes />;
}

export default App;
