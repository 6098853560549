import { React, Container, Helper } from "../../common";
import { GetMortgageRates } from "../../services/loanApi";

const RenderRateComponent = (props) => {
    const { item } = props;
    return (
        <ul className="rateBlock">
            <li className="rateBlock">
                <div className="rateHead">Term</div>
                <div className="rateResult">{item.term}</div>
            </li>
            <li className="rateBlock">
                <div className="rateHead">Interest Rate</div>
                <div className="rateResult">{item.rate}<span className="symbol">%</span></div>
            </li>
            <li className="rateBlock">
                <div className="rateHead">APR</div>
                <div className="rateResult">{item.apr}<span className="symbol">%</span></div>
            </li>
        </ul>
    );
};

const RenderComponent = (props) => {
    const { items } = props;
    return items.map((x) => (
        <li className="contentIIcol" key={x._id}>
            <RenderRateComponent item={x} />
        </li>
    ));
};

const Component = () => {

    const [initlized, setInitlized] = React.useState(false);
    const [rows, setRows] = React.useState([]);

    const FetchResults = async () => {
        global.Busy(true);
        let rslt = await GetMortgageRates();
        if (rslt.status === 100) {
            setRows(rslt.data);
        }
        global.Busy(false);
    };

    if (initlized) {
        setInitlized(false);
        setRows([]);
        FetchResults();
    }

    React.useEffect(() => { setInitlized(true); }, []);

    const FilterItems = (type) => {
        let _items = rows.filter((x) => x.type.toLowerCase() === type.toLowerCase());
        if (_items.length > 0) Helper.SortByNumber(_items, 'orderid');
        return _items;
    };

    return (
        <>
            <Container footerType={"full"} showRates={true}>
                <div className="widthfix_10">
                    <div id="genericPgBlk">
                        <div className="mortgageRateContentBlk">
                            <div className="mRate_headblk">
                                <div className="mRate_blkName">Mortgage Rates</div>
                            </div>

                            <ul className="mRate_IIcol">
                                <li className="mRate_IIcol">
                                    <div className="mRate_heading">Purchase</div>
                                    <div className="mRate_noteTxt">The below rates are estimated current rates as of: <b>{Helper.ToDate(new Date(), "YYYY-MMM-DD")}</b></div>
                                    <ul className="contentIIcol">
                                        <RenderComponent items={FilterItems('purchase')} name={"purchase"} />
                                    </ul>
                                </li>
                                {/* Refinance */}
                                <li className="mRate_IIcol">
                                    <div className="mRate_heading">Refinance</div>
                                    <div className="mRate_noteTxt">The below rates are estimated current rates as of: <b>{Helper.ToDate(new Date(), "YYYY-MMM-DD")}</b></div>
                                    <ul className="contentIIcol">
                                        <RenderComponent items={FilterItems('refinance')} name={"refinance"} />
                                    </ul>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </Container>
        </>
    );
};

export default Component;
