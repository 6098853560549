import { React, Header, Footer } from "../common";

import { RateTicker } from "./components";

const Component = ({ children, headerType, footerType, showRates, noheader, nofooter }) => {
    return (
        <>
            {!noheader && <Header type={headerType} />}
            <div id="container">
                <div id="content">
                    {showRates && <RateTicker />}
                    {children}
                </div>
            </div>
            {!nofooter && <Footer type={footerType} />}
        </>
    );
};

export default Component;