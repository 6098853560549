import { React, Link, Container, Helper, Session, useNavigate } from "../../../common";
import { GetOfficersLoans } from "../../../services/loanApi";
import { GetLoanStatuses } from "../../../services/commonApi";

const Component = () => {

    const [initlized, setInitlized] = React.useState(false);
    const [rows, setRows] = React.useState([]);

    const [setLoanStatuses] = React.useState([]);
    const [loanStatusId, setLoanStatusId] = React.useState("0");
    const [filterRows, setFilterRows] = React.useState({ childs: [] });
    const [filterItems, setFilterItems] = React.useState({});

    const Navigate = useNavigate();

    const OnLoanClicked = (e, id) => {
        e.preventDefault();
        Session.StoreAsync('floanid', id);
        Navigate("/piped");
    }

    const FetchResults = async () => {
        setFilterItems({});
        setLoanStatusId("0");

        global.Busy(true);
        setRows([]);
        let rslt = await GetOfficersLoans();
        if (rslt.status === 100) {
            let _data = rslt.data;
            Helper.SortByNumber(_data, '_id', true);
            setRows(_data);
            setFilterRows({ childs: _data });
        }
        rslt = await GetLoanStatuses();
        if (rslt.status === 100) {
            let tmp = rslt.data;
            tmp.splice(0, 0, { _id: "0", displayname: "Status" });
            setLoanStatuses(tmp);
        }
        global.Busy(false);
    };

    if (initlized) {
        setInitlized(false);
        setRows([]);
        setFilterRows({ childs: [] });
        Session.RemoveAsync('floanid');
        Session.RemoveAsync('ploanid');
        FetchResults();
    }

    React.useEffect(() => {
        setInitlized(true);
    }, []);

    /* const onInputChanged = async (path, value) => {
        const { displayname } = loanStatuses.find((x) => x._id === value);

        let _lastFilter = {
            ...filterItems,
            loanStatusText: displayname
        }
        if (value === "0" || value === 0) delete _lastFilter['loanStatusText'];
        setFilterItems(_lastFilter);
        setLoanStatusId(value);
    } */

    const OnClearFilters = (e) => {
        e.preventDefault();
        setFilterItems({});
    }

    const OnRemoveFilters = (e, name) => {
        e.preventDefault();
        let _lastFilter = filterItems;
        delete _lastFilter[name];
        if (Helper.IsJSONEmpty(filterItems)) {
            _lastFilter = {};
        }
        setFilterItems({ _lastFilter });
        if (name === 'loanStatusText') setLoanStatusId("0");
    }

    const RenderFilterDetails = () => {

        if (!Helper.IsJSONEmpty(filterItems)) {
            return (
                <div className="pipeline_filteredBlk">
                    {
                        !Helper.IsJSONEmpty(filterItems.loanStatusText) &&
                        <div className="pipeline_filter"><b>Loan Status:</b> Active<div className="fliter_close"><Link to="#" onClick={(e) => OnRemoveFilters(e, 'loanStatusText')} title="Close">&#215;</Link></div></div>
                    }
                    <input type="button" onClick={(e) => OnClearFilters(e)} className="pipeline_button_clear" value="Clear All" />
                </div>
            );
        }

        return null;
    }

    /* const GetActionStatus = (e) => {
        const tmp = loanStatuses.find((x) => x._id === e) || {};
        return tmp.displayname || "New";
    }; */

    React.useEffect(() => {
        let _lastFilter = filterRows || { childs: [] };
        let _childs = _lastFilter.childs;
        if (Helper.IsArrayEmpty(_childs)) _childs = rows;
        if (loanStatusId !== "0") _childs = _childs.filter((x) => x.actualStatusIndicator === loanStatusId);
        setFilterRows({ childs: _childs });
    }, [loanStatusId]);

    return (
        <>
            <Container footerType={"full"} showRates={true}>
                <div className="widthfix_10">
                    <div id="pipelineBlk">
                        <div className="pipelineContentBlk">
                            <div className="pipeline_headblk">
                                <div className="blkName">Pipeline</div>
                                <div className="head_right">
                                    {/* <div className="rightVerBlock">
                                        <div className="filterSelectBox_100">
                                            <select name="filterType" defaultValue={'DEFAULT'} required >
                                                <option value="DEFAULT" disabled>Program</option>
                                                <option value="#">30 Year Fixed</option>
                                                <option value="#">15 Year Fixed</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="rightVerBlock">
                                        <div className="filterSelectBox_150">
                                            <select name="filterType" defaultValue={'DEFAULT'} required >
                                                <option value="DEFAULT" disabled>Purpose</option>
                                                <option value="#">Primary Residence</option>
                                                <option value="#">Renovation</option>
                                            </select>
                                        </div>
                                    </div> */}
                                    {/* <div className="rightVerBlock">
                                        <div className="filterSelectBox_120">
                                            <DropDown
                                                path={`loanStatusId`}
                                                value={loanStatusId}
                                                onInputChildChanged={onInputChanged}
                                                items={loanStatuses}
                                                displayName={"displayname"}
                                                displayValue={"_id"}
                                            />
                                        </div>
                                    </div> */}
                                    <div className="rightVerBlock">
                                        <div className="documentTxtSearch"><input type="text" placeholder="Search by Loan No" /></div>
                                        <input type="button" className="searchicon" />
                                    </div>
                                </div>
                            </div>

                            <RenderFilterDetails />

                            <div className="contentarea_table">
                                {(!filterRows || !filterRows.childs || filterRows.childs.length === 0) && (<div className="noContent">No Leads Found. Click add new leads</div>)}
                                {filterRows && filterRows.childs && filterRows.childs.length > 0 && (
                                    <>
                                        <div className="pipelineListTable_head">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>Sl.</td>
                                                        <td>Loan ID</td>
                                                        <td>Name</td>
                                                        <td>Email ID</td>
                                                        <td>Phone</td>
                                                        <td>Purpose</td>
                                                        <td>Property Details</td>
                                                        <td>Program</td>
                                                        <td>Amount</td>
                                                        <td>Created Date</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="pipelineListTable">
                                            <table>
                                                <tbody>
                                                    {rows && rows.map((x, index) => (
                                                        <tr key={x.id}>
                                                            <td>{index + 1}</td>
                                                            <td><Link to="#" onClick={(e) => OnLoanClicked(e, x.formid)}>{x.loannumber}</Link></td>
                                                            <td>{Helper.GetStringJoin(x, ['first_name', 'last_name'])}</td>
                                                            <td>{x.email}</td>
                                                            <td>{x.mobile_number}</td>
                                                            <td>{x.purpose}</td>
                                                            <td>{x.propaddress}</td>
                                                            <td>{x.loantype}</td>
                                                            <td>{Helper.USCurrencyFormat(x.loanamount)}</td>
                                                            <td>{Helper.ToDateFormat(x.createdt, 'YYYY-MMM-DD', true)}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Container>

        </>
    );
};

export default Component;
