import { React, Link, Helper, UploadHelper, Container } from "../../../common";
import { GetOfficersLoans } from "../../../services/loanApi";
import {
    GetDocTypes, GetDocuments,
    DeleteDocuments, SubmitDocuments,
    GetAccessList, SetAccessList
} from "../../../services/documentApi";

//const maxsize = 10600;
const maxsize = 106000000;
const formats = ["PNG", "JPG", "PDF"];

const Component = () => {
    const [initlized, setInitlized] = React.useState(false);
    const [initDocs, setInitDocs] = React.useState(false);
    const [loanData, setLoanData] = React.useState({});
    const [docTypeId, setDocTypeId] = React.useState(0);
    const [docTypes, setDocTypes] = React.useState([]);
    const [docList, setDocList] = React.useState({ childs: [] });
    const [row, setRow] = React.useState({});
    const [searchLoanNumber, setSearchLoanNumber] = React.useState('');
    const [searchClicked, setSearchClicked] = React.useState(false);
    const [fileurl, setFileUrl] = React.useState(null);
    const [formatError, setFormatError] = React.useState(null);
    const [error, setError] = React.useState(null);
    const [accessDocuments, setAccessDocuments] = React.useState([]);


    const FetchResults = async () => {
        global.Busy(true);
        setDocTypes([]);
        let rslt = await GetDocTypes(0);
        if (rslt.status === 100) {
            let data = rslt.data;
            Helper.AddSelectOption(data, null, null, true);
            setDocTypes(data);
        }
        global.Busy(false);
    };

    const SearchResults = async () => {
        global.Busy(true);
        let rslt = await GetOfficersLoans(searchLoanNumber);
        if (rslt.status === 100) {
            let tmp = rslt.data;
            if (tmp && tmp.length > 0) {
                setLoanData(tmp[0]);
            }
            setSearchClicked(true);
        }
        global.Busy(false);
    };

    const OnSearchClicked = async (e) => {
        e.preventDefault();
        setLoanData({});
        setDocTypeId(0);
        if (!Helper.IsNullValue(searchLoanNumber)) await SearchResults();
    }

    const OnInputSearchChanged = (e) => {
        e.preventDefault();
        setSearchLoanNumber(e.target.value);
        setSearchClicked(false);
    }

    const OnClearSearch = (e) => {
        e.preventDefault();
        Helper.NativeInputClick("loandocs");
        setLoanData({});
        setError(null);
        setDocList({ childs: [] });
        setSearchClicked(false);
        setSearchLoanNumber(null);
    }

    if (initlized) {
        setInitlized(false);
        setLoanData({});
        setSearchClicked(false);
        //setSearchLoanNumber(null);
        FetchResults();
    }

    React.useEffect(() => {
        setInitlized(true);
    }, []);

    const OnDocTypeChanged = (e) => {
        e.preventDefault();
        setDocTypeId(parseInt(e.target.value));
    }

    const LoadDocumentsList = async () => {
        global.Busy(true);
        setDocList({ childs: [] });
        let rslt = await GetDocuments(loanData.id, docTypeId);
        if (rslt.status === 100) {
            setDocList({ childs: rslt.data });
        }

        rslt = await GetAccessList(loanData.id, docTypeId);
        global.Busy(false);
        if (rslt.status === 100) {
            setAccessDocuments({ childs: rslt.data });
        }

        global.Busy(false);
    }

    if (initDocs) {
        setInitDocs(false);
        LoadDocumentsList();
    }

    React.useEffect(() => {
        setInitDocs(true);
    }, [docTypeId]);

    const OnInputChanged = (name, val) => {
        setRow((prev) => ({
            ...prev,
            [name]: val,
        }));
    };

    const OnFileInputChanged = (e) => {
        let _file = e.target.files[0];
        setFormatError(null);
        setFileUrl(null);
        if (!Helper.IsNullValue(_file)) {
            let _ext = _file.name.split(".").pop();
            let _index = formats.findIndex((x) => x === _ext.toUpperCase());
            if (_index > -1) {
                if (_file.size > maxsize) {
                    Helper.NativeInputClick("loandocs");
                    setFormatError("File size allowed: 100mb");
                } else {
                    setFileUrl(e.target.files[0]);
                }
            } else {
                Helper.NativeInputClick("loandocs");
                setFormatError("Supported Format: " + formats.join(","));
            }
        }
    };

    const OnFileUploadClicked = async (e) => {
        e.preventDefault();
        setError(null);

        let stage = docTypes.filter((x) => parseInt(x.id) === parseInt(docTypeId));

        let obj = {
            ...row,
            loanid: loanData.id,
            typeid: docTypeId,
            type: "DOC",
            ofileName: fileurl.name,
            loannumber: loanData.loannumber,
            req: {
                lender_id: loanData.lender_id,
                borrower_id: loanData.borrower_ids,
                loan_id: loanData.loannumber,
                stage: stage,
                fileName: fileurl.name,
            },
        };

        let rslt = await UploadHelper.Upload(fileurl, obj);
        if (rslt.status !== 100) {
            Helper.NativeInputClick("loandocs");
            setError(rslt.statusText);
            return;
        }

        let _docs = docList.childs || [];
        _docs.push(rslt.data);
        setDocList({ childs: _docs });
        Helper.NativeInputClick("loandocs");
    };

    const OnDeleteClicked = async (e) => {
        e.preventDefault();
        let docId = e.target.attributes["keyid"].value;
        global.Busy(true);
        await DeleteDocuments(docId);
        global.Busy(false);
        LoadDocumentsList();
    }

    const OnSubmitClicked = async (e) => {
        e.preventDefault();

        let rslt;
        setError(null);

        global.Busy(true);
        rslt = await SetAccessList(accessDocuments.childs);
        if (rslt.status !== 100) {
            global.Busy(false);
            setError(rslt.statusText);
            return;
        }

        let data = {
            loanid: loanData.id,
            typeid: docTypeId,
            approval: 0,
        };

        let tmp = docTypes.filter((x) => parseInt(x.id) === parseInt(docTypeId));
        if (tmp && tmp.length > 0) {
            data["doctypename"] = tmp[0].name;
        }

        rslt = await SubmitDocuments(data);
        if (rslt.status !== 100) {
            global.Busy(false);
            setError(rslt.statusText);
            return;
        }
        global.Busy(false);
        setError(rslt.statusText);
        OnClearSearch(e);
    };

    return (
        <>
            <Container footerType={"full"} showRates={true}>
                <div className="widthfix_10">
                    <div id="documentBlk">
                        <div className="documentContentBlk">
                            <div className="document_headblk">
                                <div className="blkName">Upload Documents</div>
                                <div className="loanSearch">
                                    <input type="text" value={Helper.ToString(searchLoanNumber)} onChange={(e) => OnInputSearchChanged(e)} placeholder="Search by Loan No" />
                                    <input type="button" className="loanSearchIcon" onClick={(e) => OnSearchClicked(e)} />
                                </div>
                            </div>

                            {searchClicked && !Helper.IsNullValue(searchLoanNumber) && Helper.IsJSONEmpty(loanData) && (
                                <div className="contentarea_pad10" >
                                    <div className="noContent">No Loan found for {searchLoanNumber}</div>
                                </div>
                            )}

                            {!Helper.IsJSONEmpty(loanData) && (
                                <>
                                    <ul className="documentSummaryBlk">
                                        <li className="documentSummaryBlk">
                                            <div className="documentSummaryHead">Borrower Name:</div>
                                            <div className="documentSummaryText">{Helper.GetStringJoin(loanData, ['first_name', 'last_name'])}</div>
                                        </li>
                                        <li className="documentSummaryBlk">
                                            <div className="documentSummaryHead">Loan Amount:</div>
                                            <div className="documentSummaryText">{Helper.USCurrencyFormat(loanData?.loanamount)}</div>
                                        </li>
                                        <li className="documentSummaryBlk">
                                            <div className="documentSummaryHead">Issuing Authority:</div>
                                            <div className="documentSummaryText">{loanData?.issuingauth}</div>
                                        </li>
                                        <li className="documentSummaryBlk">
                                            <div className="documentSummaryHead">Est Close Date:</div>
                                            <div className="documentSummaryText">{Helper.ToDateFormat(loanData?.closingdate, "YYYY-MMM-DD")}</div>
                                        </li>
                                        <li className="documentSummaryBlk">
                                            <div className="documentSummaryHead">Property Address:</div>
                                            <div className="documentSummaryText">{loanData?.propaddress}</div>
                                        </li>
                                    </ul>
                                    <div className="document_filteredBlk">
                                        <div className="document_filter">
                                            <b>Loan Number:</b> {searchLoanNumber}<div className="fliter_close"><Link to="#" onClick={(e) => OnClearSearch(e)} title="Close">&#215;</Link></div></div>
                                        <input type="button" className="document_button_clear" onClick={(e) => OnClearSearch(e)} value="Clear All" />
                                    </div>
                                    <div className="contentHeaderBlk">Upload Documents for Loan No: {searchLoanNumber}</div>
                                    <div className="contentarea_pad10">
                                        <ul className="uploadDocSelect">
                                            <li className="uploadDocSelect">Document Type</li>
                                            <li className="uploadDocSelect">
                                                <div className="selectDocType">
                                                    <select
                                                        name="doctype"
                                                        value={Helper.ToString(docTypeId)}
                                                        onChange={(e) => OnDocTypeChanged(e)}>
                                                        {docTypes && docTypes.map((x, index) => (
                                                            <option value={x.id} key={index}>{x.name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </li>
                                            <li className="uploadDocSelect">
                                                {docTypeId > 0 && (
                                                    <ul className="uploadDocIIICol">
                                                        <li className="uploadDocIIICol">
                                                            <div className="documentContentHead">Document Name</div>
                                                            <div className="documentTxtField">
                                                                <input
                                                                    type="text"
                                                                    value={Helper.ToString(row.docname)}
                                                                    onChange={(e) =>
                                                                        OnInputChanged("docname", e.target.value)
                                                                    }
                                                                />
                                                            </div>
                                                        </li>
                                                        <li className="uploadDocIIICol">
                                                            <div className="documentContentHead">Document Information</div>
                                                            <div className="documentTxtField">
                                                                <input
                                                                    type="text"
                                                                    value={Helper.ToString(row.docinfo)}
                                                                    onChange={(e) =>
                                                                        OnInputChanged("docinfo", e.target.value)
                                                                    }
                                                                />
                                                            </div>
                                                        </li>
                                                        <li className="uploadDocIIICol">
                                                            <div className="documentContentHead">Document Upload</div>
                                                            <div className="documentContentHead_right">
                                                                {formatError && (
                                                                    <div className="fileFormat">{formatError}</div>
                                                                )}
                                                            </div>
                                                            <ul className="fileUpload">
                                                                <li className="uploadBox">
                                                                    <input
                                                                        id="loandocs"
                                                                        type="file"
                                                                        name="loandocs"
                                                                        onClick={(e) => { e.target.value = null; }}
                                                                        onChange={(e) => OnFileInputChanged(e)}
                                                                    />
                                                                </li>
                                                                <li className="uploadButton">
                                                                    <input type="submit" className="buttonUpload" value="Upload" onClick={(e) => OnFileUploadClicked(e)} />
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                )}
                                            </li>
                                        </ul>
                                    </div>

                                    {docTypeId > 0 && (
                                        <>
                                            <div className="contentHeaderBlk">Uploaded Documents</div>
                                            <div className="contentarea_table">
                                                <div className="viewDocTable_head">
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td>Document Name</td>
                                                                <td>Document Information</td>
                                                                <td>Status</td>
                                                                <td>&nbsp;</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="viewDocTable">
                                                    <table>
                                                        <tbody>
                                                            {docList && docList.childs && docList.childs.map((x) => {
                                                                return (
                                                                    <tr key={x.id}>
                                                                        <td>{x.docname}</td>
                                                                        <td>{x.docinfo}</td>
                                                                        <td>Upload Successful</td>
                                                                        <td>
                                                                            <input
                                                                                type="button"
                                                                                value="Delete"
                                                                                keyid={x.id}
                                                                                className="viewDoc_btn"
                                                                                onClick={(e) =>
                                                                                    OnDeleteClicked(e)
                                                                                }
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <ul className="document_btn_blk">
                                                <li className="document_btn_blk">
                                                    <input type="button" value="Submit" className="document_btn" onClick={(e) => OnSubmitClicked(e)} />
                                                    <input type="button" value="Cancel" className="document_btn" onClick={(e) => OnClearSearch(e)} />
                                                </li>
                                                {error && (<li className="document_btn_successError">
                                                    <div className="errormessage">{error}</div>
                                                </li>)}
                                            </ul>
                                        </>
                                    )}
                                </>
                            )}

                        </div>
                    </div>
                </div>
            </Container>

        </>
    );
};

export default Component;
