import { React, Container, useTimerSession } from "../../../common";
import { ContentLeft } from "../../components";
import {
    AppStage1, AppStage2, AppStage3, AppStage4,
    AppStage5, AppStage6, Thanks
} from "../../form1003/stages";

import { GetLoanDetailsById } from "../../../services/loanApi";

const Component = () => {

    const [initlized, setInitlized] = React.useState(false);
    const [row, setRow] = React.useState({});
    const [floanId] = useTimerSession("floanid");
    const [tabIndex, setTabIndex] = React.useState(1);

    const FetchResults = async () => {
        global.Busy(true);
        let rslt = await GetLoanDetailsById(floanId);
        if (rslt.status === 100) {
            setRow(rslt.data);
        }

        global.Busy(false);
    };

    if (initlized) {
        setInitlized(false);
        setRow({});
        FetchResults();
    }

    React.useEffect(() => {
        setInitlized(true);
    }, []);

    const OnLinkClicked = (e) => {
        setTabIndex(e);
    }

    return (
        <>
            <Container footerType={"full"} showRates={true}>
                <div className="widthfix">
                    <div id="pipelineBlk">
                        <ul className="pgIIcol_leftNavi">
                            <li className="pgIIcol_leftNavi">
                                <ContentLeft form1003={true} id={row?.userid} loanNumber={row?.loanNumber} />
                            </li>
                            <li className="pgIIcol_leftNavi">
                                {tabIndex === 1 && <AppStage1 loanOffierDisabled={true} noupdate={true} formId={floanId} refNumber={row?.loanNumber} onTabClicked={OnLinkClicked} />}
                                {tabIndex === 2 && <AppStage2 noupdate={true} formId={floanId} onTabClicked={OnLinkClicked} />}
                                {tabIndex === 3 && <AppStage3 noupdate={true} formId={floanId} onTabClicked={OnLinkClicked} />}
                                {tabIndex === 4 && <AppStage4 noupdate={true} formId={floanId} onTabClicked={OnLinkClicked} />}
                                {tabIndex === 5 && <AppStage5 noupdate={true} formId={floanId} onTabClicked={OnLinkClicked} />}
                                {tabIndex === 6 && <AppStage6 noupdate={true} formId={floanId} onTabClicked={OnLinkClicked} />}
                                {tabIndex === 7 && <Thanks formId={floanId} />}
                            </li>
                        </ul>
                    </div>
                </div>
            </Container>

        </>
    );
};

export default Component;
