import API from "./api";
import session from "../session";

const CallBack = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.post(`common/callback`, input, {
      headers: token,
    });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

const ContactUs = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.post(`common/contactus`, input, {
      headers: token,
    });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

const RequestForQuote = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.post(`common/quote`, input, {
      headers: token,
    });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

const BecomeBranch = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.post(`common/branch`, input, {
      headers: token,
    });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

const SubcribeForUpdates = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.post(`common/subcribeupdates`, input, {
      headers: token,
    });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

const GetLoanStatuses = async () => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`common/loanstatus`, { headers: token });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

const SetLoanStatus = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.post(`common/loanstatus`, input, { headers: token });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

export {
  CallBack,
  ContactUs,
  RequestForQuote,
  BecomeBranch,
  SubcribeForUpdates,
  GetLoanStatuses,
  SetLoanStatus
};
