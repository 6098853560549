import { React, Helper } from "../../../common";
import { GetList } from "../../../services/enumsApi";
import { AddLoan, GetLoan, GetLoadIdByMappingId } from "../../../services/loanApi";
import Container from "./container1003";
import { RenderGiftsOrGrants, RenderLoanDetails, RenderPropertyDetails, RenderOtherProperties } from "./childs";
import StageHelperPopup from "../help/stage2";

const Component = ({ formId, approvalId, onTabClicked, noupdate }) => {
  const [initlized, setInitlized] = React.useState(false);
  const [errors, setErrors] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(null);
  const [loanId, setLoanId] = React.useState(null);
  const [row, setRow] = React.useState({});

  const [mortgageTypes, setMortgageTypes] = React.useState([]);
  const [amortizationTypes, setAmortizationTypes] = React.useState([]);
  const [loanPurposeTypes, setLoanPurposeTypes] = React.useState([]);
  const [occupancyTypes, setOccupancyTypes] = React.useState([]);
  const [sourceTypes, setSourceTypes] = React.useState([]);
  const [unitTypes, setUnitTypes] = React.useState([]);
  const [giftsOrGrantTypes, setGiftsOrGrantTypes] = React.useState([]);
  const [propertyTypes, setPropertyTypes] = React.useState([]);
  const [statesList, setStatesList] = React.useState([]);
  const [state, setState] = React.useState(false);
  const [lastStage, setLastStage] = React.useState(2);
  let stageIndicator = 2;

  const FetchLookUps = async () => {
    return new Promise(async (resolve) => {
      global.Busy(true);
      let idList = [1, 2, 3, 4, 5, 6, 16, 22, 18];
      let rslt = await GetList(idList);
      if (rslt.status === 100) {
        let t, _list;
        _list = rslt.data || [];
        t = _list.filter((x) => parseInt(x.type) === 1);
        Helper.AddSelectOption(t);
        setMortgageTypes(t);
        t = _list.filter((x) => parseInt(x.type) === 2);
        Helper.AddSelectOption(t, true, 'value');
        setAmortizationTypes(t);
        t = _list.filter((x) => parseInt(x.type) === 3);
        Helper.AddSelectOption(t, true, 'value');
        setLoanPurposeTypes(t);
        t = _list.filter((x) => parseInt(x.type) === 4);
        Helper.AddSelectOption(t, true, 'value');
        setOccupancyTypes(t);
        t = _list.filter((x) => parseInt(x.type) === 5);
        Helper.AddSelectOption(t, true, 'value');
        setUnitTypes(t);
        t = _list.filter((x) => parseInt(x.type) === 6);
        Helper.AddSelectOption(t, true, 'value');
        setGiftsOrGrantTypes(t);
        t = _list.filter((x) => parseInt(x.type) === 16);
        Helper.AddSelectOption(t, true, 'value');
        setSourceTypes(t);
        t = _list.filter((x) => parseInt(x.type) === 18);
        Helper.AddSelectOption(t, true, 'value');
        setStatesList(t);
        t = _list.filter((x) => parseInt(x.type) === 22);
        Helper.AddSelectOption(t, true, 'value');
        setPropertyTypes(t);
      }

      global.Busy(false);
      return resolve(true);
    });
  };

  const FetchResults = async () => {
    await FetchLookUps();
    global.Busy(true);

    setRow([]);
    let stageTwo, rslt;
    if (!Helper.IsNullValue(loanId)) {
      rslt = await GetLoan(stageIndicator, loanId);
      if (rslt.status === 100) {
        let _data = rslt.data;
        setLastStage(_data.stageIndicator);
        stageTwo = _data.stageTwo;
      }
    }

    setRow(stageTwo);
    global.Busy(false);
  };

  if (initlized) {
    setInitlized(false);
    setErrors([]);
    setError(null);
    setSuccess(null);
    FetchResults();
  }

  const OnRecordValidate = () => {
    setError("");
    setErrors([]);
    const { errors, valid } = Helper.IsFormValid('stageTwo');

    if (!valid) {
      setErrors(errors);
      setError("Fill all required details");
      return false;
    }
    return true;
  };

  const OnUpdateRecord = async (e, saved) => {
    e.preventDefault();
    setState(!state);
    if (!OnRecordValidate()) return;
    setErrors([]);
    setError(null);
    setSuccess(null);
    row._id = loanId;
    row.stageIndicator = stageIndicator;

    let applicants = JSON.parse(JSON.stringify(row));
    const removeItems = ['stageIndicator', 'statusIndicator', '_id'];
    removeItems.forEach(e => {
      delete applicants[e];
    });

    stageIndicator = lastStage > 2 ? lastStage : 2

    let newObj = {
      _id: loanId,
      statusIndicator: 0,
      stageIndicator,
      stageTwo: applicants,
    };

    global.Busy(true);
    let rslt = await AddLoan(newObj);
    global.Busy(false);
    if (rslt.status !== 100) {
      setErrors(rslt.errors);
      setError(rslt.statusText);
      return;
    } else {
      let _data = rslt.data;
      setRow(_data.stageTwo);
    }
    if (saved) {
      setSuccess(Helper.SavedMessage);
    } else {
      if (onTabClicked) onTabClicked(3);
    }
  };

  const GetItemsList = (name) => {
    if (
      row &&
      row[name] &&
      (!Helper.IsArrayNull(row[name]) || !Helper.IsJSONEmpty(row[name]))
    )
      return row[name];
    let tmp = [{ index: 0, delete: false }];
    if (name === "giftsGrants") {
      tmp = [{ index: 0, delete: false, owner: "Borrower" }];
      //row[name] = tmp;
      OnDirectInputChanged(name, tmp);
      return tmp;
    } else if (name === "otherMortgageLoans") {
      tmp = [{ index: 0, delete: false, owner: "Borrower" }];
      //row[name] = tmp;
      OnDirectInputChanged(name, tmp);
      return tmp;
    }

    //row[name] = tmp;
    OnDirectInputChanged(name, tmp);
    return tmp;
  };

  const OnAddArrayList = (name, json, options) => {
    let tmp = Helper.AddJsonArrayObject(row, name, json, options);
    OnDirectInputChanged(name, tmp);
  };

  const OnInputChanged = async (path, value, index) => {
    let newRow = Helper.ModifyJsonArrayObject4(row, path, value);
    setRow(newRow);
    setState(!state);
  };

  const OnDirectInputChanged = async (name, value) => {
    setRow((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const OnBlurError = (name, val, clear) => {
    setSuccess(null);
    let _errors = [];
    if (errors && errors.length > 0) _errors = errors.filter((x) => x.field !== name);
    if (Helper.IsNullValue(clear)) _errors.push({ field: name, value: val });
    setErrors(_errors);
    setError(val);
  };

  const OnInputClicked = (name) => {
    setSuccess(null);
    let _err = null;
    if (errors && errors.length > 0) {
      let _findIndex = errors.findIndex((x) => x.field === name);
      if (_findIndex > -1) {
        _err = errors[_findIndex].value;
      }
    }
    setError(_err);
  };

  /*  const IsDisableNext = () => {
    return parseInt(lastStage) < parseInt(stageIndicator);
  }; */

  const OnLinkClicked = (a) => {
    if (onTabClicked) onTabClicked(a);
  }

  React.useEffect(() => {
    if (!Helper.IsNullValue(approvalId)) {
      const fn = async () => {
        let rslt = await GetLoadIdByMappingId(approvalId, 'pre');
        if (rslt.status === 100) {
          setLoanId(rslt.data._id);
        }
      };
      fn();
    }
  }, [approvalId]);

  React.useEffect(() => {
    if (!Helper.IsNullValue(formId)) {
      setLoanId(formId);
    }
  }, [formId]);

  React.useEffect(() => {
    if (!Helper.IsNullValue(loanId)) {
      setInitlized(true);
    }
  }, [loanId]);

  const MoveNextPrev = async (e, prev) => {
    e.preventDefault();
    if (prev) {
      OnLinkClicked(1);
    }
    else {
      OnLinkClicked(3);
    }
  };

  return (
    <>
      <Container selected={2} lastStage={lastStage} onTabClicked={OnLinkClicked}>
        <form id="stageTwo">
          <ul className="form1003IIcol">
            <li className="form1003IIcol">
              <RenderLoanDetails
                item={row}
                mortgageTypes={mortgageTypes}
                amortizationTypes={amortizationTypes}
                loanPurposeTypes={loanPurposeTypes}
                occupancyTypes={occupancyTypes}
                propertyTypes={propertyTypes}
                onBlurError={OnBlurError}
                onInputClicked={OnInputClicked}
                onInputChanged={OnInputChanged}
                errors={errors}
              />
              <RenderPropertyDetails
                tag={"property"}
                item={row}
                unitTypes={unitTypes}
                statesList={statesList}
                onInputChanged={OnInputChanged}
                errors={errors}
                onBlurError={OnBlurError}
                onInputClicked={OnInputClicked}
              />
            </li>
            <li className="form1003IIcol">
              <RenderGiftsOrGrants
                tag={"giftsGrants"}
                items={{ childs: GetItemsList("giftsGrants") }}
                owner={"Borrower"}
                giftsOrGrantTypes={giftsOrGrantTypes}
                sourceTypes={sourceTypes}
                onInputChanged={OnInputChanged}
                onAddArrayList={OnAddArrayList}
                onBlurError={OnBlurError}
                onInputClicked={OnInputClicked}
                errors={errors}
                allowAdd={true}
                allowDelete={true}
              />
              <RenderOtherProperties
                tag={"otherMortgageLoans"}
                owner={"Borrower"}
                items={{ childs: GetItemsList("otherMortgageLoans") }}
                onInputChanged={OnInputChanged}
                onAddArrayList={OnAddArrayList}
                onBlurError={OnBlurError}
                onInputClicked={OnInputClicked}
                errors={errors}
                allowAdd={true}
                allowDelete={true}
              />
            </li>
          </ul>
        </form>
        <ul className="form_btn_successError_blk">
          <li className="form_btn_steps_label">
            {noupdate && (
              <>
                <input
                  type="button"
                  defaultValue="Prev"
                  className="form_btn_steps"
                  onClick={(e) => MoveNextPrev(e, true)}
                />
                <input
                  type="button"
                  defaultValue="Next"
                  className="form_btn_steps"
                  onClick={(e) => MoveNextPrev(e)}
                />
              </>
            )}
            {!noupdate && (
              <>
                <input
                  type="button"
                  defaultValue="Save & Finish Later"
                  className="form_btn_steps"
                  onClick={(e) => OnUpdateRecord(e, true)}
                />
                <input
                  type="button"
                  defaultValue="Continue"
                  className="form_btn_steps"
                  onClick={(e) => OnUpdateRecord(e, false)}
                />
              </>
            )}
          </li>
          {error && (
            <li className="form_successError_steps">
              <div className="errormessage">{error}</div>
            </li>
          )}
          {success && (
            <li className="form_successError_steps">
              <div className="successmessage">{success}</div>
            </li>
          )}
        </ul>
      </Container>
      <StageHelperPopup />
    </>
  );
};

export default Component;
