import { React, Container, useTimerSession } from "../../../common";
import { ContentLeft } from "../../components";
import ConcentComponent from "../../consents";

const Component = () => {
    const [puserId] = useTimerSession("puserid");
    const [ploanRef] = useTimerSession("ploanref");

    return (
        <>
            <Container footerType={"full"} showRates={true}>
                <div className="widthfix">
                    <div id="leadsBlk">
                        <ul className="pgIIcol_leftNavi">
                            <li className="pgIIcol_leftNavi">
                                <ContentLeft id={puserId} loanNumber={ploanRef} />
                            </li>
                            <ConcentComponent form1003={false} />
                        </ul>
                    </div>
                </div>
            </Container>
        </>
    );
};

export default Component;
