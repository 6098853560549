import { React, Container } from "../../common";

const Component = () => {
  //const [name, setName] = React.useState("");
  //const [loanId] = useTimerSession('loanid');

  /* React.useEffect(() => {
    const fn = async () => {
      global.Busy(true);
      if (!Helper.IsNullValue(loanId)) {
        let rslt = await GetLoan(1, loanId);
        if (rslt.status === 100) {
          let _data = rslt.data.stageOne.applicants[0].borrower;
          let _name = Helper.GetStringJoin(_data, ["firstName", "middleName", "lastName"]);
          setName(_name);
          Session.Remove("loanid");
        }
      }
      global.Busy(false);
    };
    fn();
  }, [loanId]); */

  return (
    <>
      <Container>
        <div id="container">
          <div id="content">
            <div className="widthfix_10px">
              <div className="form1003_pgBlk">
                <div className="formFinishHead">Thank You</div>
                {/* <div className="formFinishText_b">Dear {name}</div> */}
                <div className="formFinishText_b">&nbsp;</div>
                <div className="formFinishText">
                  Your application has been submitted, and you will be hearing
                  from someone from our office soon.
                </div>
                <div className="formFinishText">
                  If you have any questions in the meantime, please call us at
                  (888) 975-1374.
                </div>
                <div className="spacer_25margin_bottom"></div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Component;
