import { React, Container } from "../../common";

const Component = () => {
    return (
        <>
            <Container footerType={"full"} showRates={true}>
                Campaign
            </Container>

        </>
    );
};

export default Component;
