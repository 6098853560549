import { Helper, React, useTimerSession } from "../../common";
import { GetPreBorrowers } from "../../services/preapprovalApi";
import { GetConsentsRequest, SetConsentsRequest, OtpValidateRequest } from "../../services/consentsApi";

const RenderCoBorrower = (props) => {
    const { item, onReloadResults, form1003 } = props;

    const [sentRequest, setSentRequest] = React.useState(false);
    const [coborrowers, setCoborrowers] = React.useState({ childs: [] });
    const [otp, setOTP] = React.useState("");
    const [error, setError] = React.useState(null);

    React.useEffect(() => {
        setCoborrowers({ childs: item.applicants })
    }, [item]);

    const RenderButton = ({ parent, citem }) => {
        if (parseInt(citem.id) === 0) return <span style={{ color: "#c21200" }}>Not Registered</span>;
        if (!sentRequest && parseInt(citem.statusid) === -2) {
            return <input type="button" onClick={(e) => OnRequestClicked(e, parent, citem)}
                value="Request Consent" className="requestButton" />;
        }
        return null;
    }

    const RenderConsentStatus = ({ citem }) => {
        let tmpStatus = "";

        if (Helper.ToInteger(citem.statusid) === 1) {
            if (Helper.ToBool(citem.selfApproved)) {
                tmpStatus = `Consent provided on ${Helper.ToDateFormat(citem.updatedAt, 'YYYY-MMM-DD')}`;
            } else {
                tmpStatus = `Consent approved on ${Helper.ToDateFormat(citem.updatedAt, 'YYYY-MMM-DD')}`;
            }
        } else if (Helper.ToInteger(citem.statusid) === 0) {
            tmpStatus = `Consent denined on ${Helper.ToDateFormat(citem.createdAt, 'YYYY-MMM-DD')}`;
        }
        else if (Helper.ToInteger(citem.statusid) === -1) {
            tmpStatus = `Consent pending for approval requested on ${Helper.ToDateFormat(citem.createdAt, 'YYYY-MMM-DD')}`;
        }
        return <div className="consentTxt">{tmpStatus}</div>;
    }

    const OnRequestClicked = async (e, parent, citem) => {
        e.preventDefault();
        setSentRequest(false);
        setError(null);
        let _req = {
            mapid: parent.mapid,
            consentid: parent._id,
            applicants: [{ email: citem.email }],
            requesttype: form1003 ? "FORM" : "PRE"
        }
        global.Busy(true);
        let rslt = await SetConsentsRequest(_req);
        global.Busy(false);
        if (rslt.status === 100) {
            onReloadResults();
            setSentRequest(true);
        } else {
            setError(rslt.statusText);
        }
    }

    const OnValidateOTPClicked = async (e, citem) => {
        e.preventDefault();
        setError(null);
        if (Helper.IsNullValue(otp)) {
            setError("OPT Number is mandatory");
            return;
        }

        global.Busy(true);
        let rslt = await OtpValidateRequest(citem.mapkey, otp);
        global.Busy(false);
        if (rslt.status === 100) {
            onReloadResults();
        } else {
            setError(rslt.statusText);
        }
    }

    return (
        <li className="contentIIIcol">
            <div className="contentHeaderBlk">{item.title}</div>
            <div className="contentarea_pad10">
                <div className="consentTxtHead">{item.question}</div>
                <ul className="consentBorDetails">
                    <li className="consentBorDetails" style={{ width: "100%" }}>
                        <table style={{ width: "100%" }}>
                            <tbody>
                                <tr>
                                    <td style={{ width: "100px", height: 21 }}>Name</td>
                                    <td style={{ width: "90px", height: 21 }}>Phone</td>
                                    <td>Action</td>
                                </tr>
                                {coborrowers && coborrowers.childs && coborrowers.childs.length > 0 && coborrowers.childs.map((z) => (
                                    <tr key={z.id}>
                                        <td style={{ width: "100px", height: 21 }}>{z.fullname}</td>
                                        <td style={{ width: "90px", height: 21 }}>{z.phone}</td>
                                        <td>
                                            {parseInt(z.statusid) !== -2 && <RenderConsentStatus citem={z} />}
                                            <RenderButton parent={item} citem={z} />
                                            {(sentRequest || parseInt(z.statusid) === -1) && (
                                                <ul className="OTPBtn">
                                                    <li className="OTPBtn"><input type="text" value={Helper.ToString(otp)}
                                                        onChange={(e) => { setOTP(e.target.value) }} placeholder="Co-Borrower OTP" /></li>
                                                    <li className="OTPBtn"><input type="button" value="Submit" onClick={(e) => OnValidateOTPClicked(e, z)} className="OTPSubmitButton" /></li>
                                                </ul>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </li>
                </ul>
                {error && <div className="errormessage_center">{error}</div>}
            </div>
        </li>
    )

}

const RenderBorrower = (props) => {

    const { item, applicants, onReloadResults, form1003 } = props;
    const [sentRequest, setSentRequest] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [consentStatus, setConsentStatus] = React.useState("");
    const [otp, setOTP] = React.useState("");

    React.useEffect(() => {
        let tmpStatus = "";
        setSentRequest(false);
        setError(null);
        if (Helper.ToInteger(item.statusid) === 1) {
            if (Helper.ToBool(item.selfApproved)) {
                tmpStatus = `Consent provided on ${Helper.ToDateFormat(item.updatedAt, 'YYYY-MMM-DD')}`;
            } else {
                tmpStatus = `Consent approved on ${Helper.ToDateFormat(item.updatedAt, 'YYYY-MMM-DD')}`;
            }
        } else if (Helper.ToInteger(item.statusid) === 0) {
            tmpStatus = `Consent denined on ${Helper.ToDateFormat(item.createdAt, 'YYYY-MMM-DD')}`;
        }
        else if (Helper.ToInteger(item.statusid) === -1) {
            tmpStatus = `Consent pending for approval requested on ${Helper.ToDateFormat(item.createdAt, 'YYYY-MMM-DD')}`;
        }
        setConsentStatus(tmpStatus);
    }, [item]);

    const OnRequestClicked = async (e) => {
        e.preventDefault();
        setSentRequest(false);
        setError(null);
        let _req = {
            mapid: applicants._id,
            consentid: item._id,
            applicants: [{ email: applicants.borrower.email }],
            requesttype: form1003 ? "FORM" : "PRE"
        }
        global.Busy(true);
        let rslt = await SetConsentsRequest(_req);
        global.Busy(false);
        if (rslt.status === 100) {
            onReloadResults();
            setSentRequest(true);
        } else {
            setError(rslt.statusText);
        }
    }

    const OnValidateOTPClicked = async (e) => {
        e.preventDefault();
        setError(null);
        if (Helper.IsNullValue(otp)) {
            setError("OPT Number is mandatory");
            return;
        }

        global.Busy(true);
        let rslt = await OtpValidateRequest(item.mapkey, otp);
        global.Busy(false);
        if (rslt.status === 100) {
            onReloadResults();
        } else {
            setError(rslt.statusText);
        }
    }

    return (
        <li className="contentIIIcol">
            <div className="contentHeaderBlk">{item.title}</div>
            <div className="contentarea_pad10">
                <div className="consentTxtHead">{item.question}</div>
                {parseInt(item.statusid) !== -2 && <div className="consentTxt">{consentStatus}</div>}
                {parseInt(item.statusid) === -2 && <input type="button" onClick={(e) => OnRequestClicked(e)}
                    value="Request Consent" className="requestButton" />}
                {(sentRequest || parseInt(item.statusid) === -1) && (
                    <ul className="OTPBtn">
                        <li className="OTPBtn"><input type="text" value={Helper.ToString(otp)}
                            onChange={(e) => { setOTP(e.target.value) }} placeholder="Borrower OTP" /></li>
                        <li className="OTPBtn"><input type="button" value="Submit" onClick={(e) => OnValidateOTPClicked(e)} className="OTPSubmitButton" /></li>
                    </ul>
                )}
                {error && <div className="errormessage_center">{error}</div>}
            </div>
        </li>
    )

}

const Component = (props) => {

    const [initlized, setInitlized] = React.useState(false);
    const [loadResults, setLoadResults] = React.useState(false);
    const [row, setRow] = React.useState({ childs: [] });
    const [applicants, setApplicants] = React.useState({});
    const [ploanRef] = useTimerSession("ploanref");
    const [ploanid] = useTimerSession("ploanid");
    const [error, setError] = React.useState(null);
    const [success, setSuccess] = React.useState(null);
    const [isCoborrowers, setIsCoborrowers] = React.useState(false);

    React.useEffect(() => {
        setInitlized(true);
    }, []);

    const LoadSearchResults = async () => {
        global.Busy(true);
        let rslt = await GetConsentsRequest(ploanid, isCoborrowers, "PRE");
        if (rslt.status === 100) {
            let tmp = rslt.data;
            tmp = tmp.filter((x) => x.statusid !== undefined);
            setRow({ childs: tmp });
        }
        global.Busy(false);
    };

    const FetchPreResults = async () => {
        let _applicants = {};
        global.Busy(true);
        let rslt = await GetPreBorrowers(ploanid);
        if (rslt.status === 100) _applicants = rslt.data;

        let coBorrwer = true;
        if (!Helper.IsNullValue(_applicants['cboborrower'])) coBorrwer = false;

        setIsCoborrowers(coBorrwer);

        setApplicants(_applicants);

        global.Busy(false);

        setLoadResults(true);
    };

    const OnStatusMessage = (status, message) => {
        if (message && status) {
            setSuccess(message);
        } else if (message && !status) {
            setError(message);
        } else {
            setError(null);
            setSuccess(null);
        }
    }

    if (initlized) {
        setInitlized(false);
        setError(null);
        setSuccess(null);
        FetchPreResults();
    }

    if (loadResults) {
        setLoadResults(false);
        LoadSearchResults();
    }

    const OnReloadResults = () => {
        setLoadResults(true);
    }

    return (
        <>
            <li className="pgIIcol_leftNavi">
                <div className="leadContentBlk">
                    <div className="lead_headblk_border">
                        <div className="blkName">Consents for ID - {ploanRef}</div>
                    </div>
                    <div className="consentLo">
                        <ul className="contentIIIcol">
                            {row && row.childs && row.childs.map((x) => {
                                if (x.type === 1) {
                                    return <RenderBorrower {...props}
                                        onStatusMessage={OnStatusMessage} onReloadResults={OnReloadResults}
                                        applicants={applicants} key={x._id} item={x} />
                                } else if (x.type === 2) {
                                    return <RenderCoBorrower {...props}
                                        onStatusMessage={OnStatusMessage} onReloadResults={OnReloadResults}
                                        applicants={applicants} key={x._id} item={x} />
                                }
                                return null
                            })}
                        </ul>
                    </div>
                    {error && <div className="errormessage_center">{error}</div>}
                    {success && <div className="errormessage_center">{success}</div>}
                </div>
            </li>
        </>
    );
};

export default Component;