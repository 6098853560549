import { React, Link, Helper, Container, useNavigate, Session, DropDown2 as DropDown } from "../../../common";
import { GetPreapprovalList } from "../../../services/preapprovalApi";
import { GetList } from "../../../services/enumsApi";
import { AddLeadDialog } from "../../popups";
import { GetLoanStatuses } from "../../../services/commonApi";

const Component = () => {

    const [initlized, setInitlized] = React.useState(false);
    const [showAddLead, setShowAddLead] = React.useState(false);
    const [rows, setRows] = React.useState([]);
    const [statesList, setStatesList] = React.useState([]);

    const [loanStatuses, setLoanStatuses] = React.useState([]);
    const [loanStatusId, setLoanStatusId] = React.useState("0");
    const [filterRows, setFilterRows] = React.useState({ childs: [] });
    const [filterItems, setFilterItems] = React.useState({});

    const NavigateTo = useNavigate();

    React.useEffect(() => {
        setInitlized(true);
        Session.Remove("puserid");
        Session.Remove("ploanid");
        Session.Remove("loanid");
        Session.Remove("ploanref");
    }, []);

    const FetchLookUps = async () => {
        return new Promise(async (resolve) => {
            global.Busy(true);
            let idList = [18];
            let rslt = await GetList(idList);
            if (rslt.status === 100) {
                let t, _list;
                _list = rslt.data || [];
                t = _list.filter((x) => parseInt(x.type) === 18);
                Helper.AddSelectOption(t, true, 'value');
                setStatesList(t);
            }
            global.Busy(false);
            return resolve(true);
        });
    };

    const FetchResults = async () => {
        setFilterItems({});
        setLoanStatusId("0");

        await FetchLookUps();
        global.Busy(true);

        let rslt = await GetPreapprovalList();
        if (rslt.status === 100) {
            let _data = rslt.data;
            Helper.SortByNumber(_data, '_id', true);
            setRows(_data);
            setFilterRows({ childs: _data });
        }

        rslt = await GetLoanStatuses();
        if (rslt.status === 100) {
            let tmp = rslt.data;
            tmp.splice(0, 0, { _id: "0", displayname: "Status" });
            setLoanStatuses(tmp);
        }

        global.Busy(false);
    };

    if (initlized) {
        setInitlized(false);
        setRows([]);
        setFilterRows({ childs: [] });
        FetchResults();
    }

    const OnLoadSelected = async (e, id, uid, num) => {
        e.preventDefault();
        await Session.StoreAsync("ploanref", num);
        await Session.StoreAsync("ploanid", id);
        await Session.StoreAsync("puserid", uid);
        NavigateTo("/leadd");
    }

    const OnAddLeadClicked = async (e) => {
        e.preventDefault();
        setShowAddLead(true);
    }

    const OnSubmitClicked = async (e) => {
        setShowAddLead(false);
        await FetchResults();
    };

    const OnCloseClicked = (e) => {
        setShowAddLead(false);
    };

    const onInputChanged = async (path, value) => {
        const { displayname } = loanStatuses.find((x) => x._id === value);

        let _lastFilter = {
            ...filterItems,
            loanStatusText: displayname
        }
        if (value === "0" || value === 0) delete _lastFilter['loanStatusText'];
        setFilterItems(_lastFilter);
        setLoanStatusId(value);
    }

    const OnClearFilters = (e) => {
        e.preventDefault();
        setFilterItems({});
    }

    const OnRemoveFilters = (e, name) => {
        e.preventDefault();
        let _lastFilter = filterItems;
        delete _lastFilter[name];
        if (Helper.IsJSONEmpty(filterItems)) {
            _lastFilter = {};
        }
        setFilterItems({ _lastFilter });
        if (name === 'loanStatusText') setLoanStatusId("0");
    }

    const RenderFilterDetails = () => {
        if (!Helper.IsJSONEmpty(filterItems)) {
            return (
                <div className="lead_filteredBlk">
                    {
                        !Helper.IsJSONEmpty(filterItems.loanStatusText) &&
                        <div className="lead_filter"><b>Loan Status:</b> Active<div className="fliter_close"><Link to="#" onClick={(e) => OnRemoveFilters(e, 'loanStatusText')} title="Close">&#215;</Link></div></div>
                    }
                    <input type="button" onClick={(e) => OnClearFilters(e)} className="lead_button_clear" value="Clear All" />
                </div>
            );
        }

        return null;
    }

    const GetActionStatus = (e) => {
        const tmp = loanStatuses.find((x) => x._id === e) || {};
        return tmp.displayname || "New";
    };

    React.useEffect(() => {
        let _lastFilter = filterRows || { childs: [] };
        let _childs = _lastFilter.childs;
        if (Helper.IsArrayEmpty(_childs)) _childs = rows;
        if (loanStatusId !== "0") _childs = _childs.filter((x) => x.actualStatusIndicator === loanStatusId);
        setFilterRows({ childs: _childs });
    }, [loanStatusId]);

    return (
        <>
            <Container footerType={"full"} showRates={true}>
                <div className="widthfix_10">
                    <div id="leadsBlk">
                        <div className="leadContentBlk">
                            <div className="lead_headblk">
                                <div className="blkName">Leads List</div>
                                <div className="head_right">
                                    <div className="rightVerBlock" style={{ display: "none" }}>
                                        <div className="filterSelectBox_100">
                                            <select name="filterType" defaultValue={'DEFAULT'} required >
                                                <option value="DEFAULT" disabled>Rate Lock</option>
                                                <option value="#">Yes</option>
                                                <option value="#">No</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="rightVerBlock">
                                        <div className="filterSelectBox_120">
                                            <DropDown
                                                path={`loanStatusId`}
                                                value={loanStatusId}
                                                onInputChildChanged={onInputChanged}
                                                items={loanStatuses}
                                                displayName={"displayname"}
                                                displayValue={"_id"}
                                            />
                                        </div>
                                    </div>
                                    <div className="rightVerBlock">
                                        <input type="button" className="rightButton" value="Add New Lead" onClick={(e) => OnAddLeadClicked(e)} />
                                    </div>
                                </div>
                            </div>

                            <RenderFilterDetails />

                            <div className="contentarea_table">
                                {(!filterRows || !filterRows.childs || filterRows.childs.length === 0) && (<div className="noContent">No Leads Found. Click add new leads</div>)}
                                {filterRows && filterRows.childs && filterRows.childs.length > 0 && (
                                    <>
                                        <div className="leadListTable_head">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>Sl.</td>
                                                        <td>Lead ID</td>
                                                        <td>Name</td>
                                                        <td>Email ID</td>
                                                        <td>Phone</td>
                                                        <td>Purpose</td>
                                                        <td>Property Details</td>
                                                        <td>Program</td>
                                                        <td>Amount</td>
                                                        <td>Created Date</td>
                                                        <td>Action</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="leadListTable">
                                            <table>
                                                <tbody>
                                                    {filterRows.childs && filterRows.childs.map((x, index) => (
                                                        <tr key={x._id}>
                                                            <td>{index + 1}</td>
                                                            <td><Link to="#" onClick={(e) => OnLoadSelected(e, x._id, x.userid, x.refNumber)} >{x.refNumber}</Link></td>
                                                            <td>{x.Borrower}</td>
                                                            <td>{x.email}</td>
                                                            <td>{x.Phone}</td>
                                                            <td>{x.Purpose}</td>
                                                            <td>{x.Address}</td>
                                                            <td>{x.Program}</td>
                                                            <td>{x.LoanAmount}</td>
                                                            <td>{Helper.ToDateFormat(x.DateOn, "YYYY-MM-DD")}</td>
                                                            <td>{GetActionStatus(x.actualStatusIndicator)}</td>

                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <AddLeadDialog display={showAddLead} statesList={statesList}
                    onSubmittedNewLead={OnSubmitClicked} onCloseClicked={OnCloseClicked} />
            </Container >
        </>
    );
};

export default Component;
