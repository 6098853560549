import { React, Container, useTimerSession } from "../../../common";
import { ContentLeft } from "../../components";

import {
    AppStage1, AppStage2, AppStage3,
    AppStage4, AppStage5, AppStage6, Thanks
} from "../../form1003/stages";

const Component = () => {
    const [tabIndex, setTabIndex] = React.useState(1);
    const [ploanId] = useTimerSession("ploanid");
    const [puserId] = useTimerSession("puserid");
    const [ploanRef] = useTimerSession("ploanref");

    const OnLinkClicked = (e) => {
        setTabIndex(e);
    }

    return (
        <>
            <Container footerType={"full"} showRates={true}>
                <div className="widthfix">
                    <div id="leadsBlk">
                        <ul className="pgIIcol_leftNavi">
                            <li className="pgIIcol_leftNavi">
                                <ContentLeft id={puserId} loanNumber={ploanRef} />
                            </li>
                            <li className="pgIIcol_leftNavi">
                                {tabIndex === 1 && <AppStage1 loanOffierDisabled={true} approvalId={ploanId} refNumber={ploanRef} onTabClicked={OnLinkClicked} />}
                                {tabIndex === 2 && <AppStage2 approvalId={ploanId} onTabClicked={OnLinkClicked} />}
                                {tabIndex === 3 && <AppStage3 approvalId={ploanId} onTabClicked={OnLinkClicked} />}
                                {tabIndex === 4 && <AppStage4 approvalId={ploanId} onTabClicked={OnLinkClicked} />}
                                {tabIndex === 5 && <AppStage5 approvalId={ploanId} onTabClicked={OnLinkClicked} />}
                                {tabIndex === 6 && <AppStage6 approvalId={ploanId} onTabClicked={OnLinkClicked} />}
                                {tabIndex === 7 && <Thanks approvalId={ploanId} />}
                            </li>
                        </ul>
                    </div>
                </div>
            </Container>
        </>
    );
};

export default Component;
