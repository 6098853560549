import { React, Container, useTimerSession, Helper } from "../../../common";
import { ContentLeft } from "../../components";
import { GetLoanDetailsById, FindLoanById } from "../../../services/loanApi";

const GeneralDetails = [
    { label: "Loan Purpose", name: "purpose", index: 1 },
    { label: "Loan Type", name: "loantype", index: 1 },
    { label: "Loan Number", name: "loannumber", index: 1 },
    { label: "Lender/Investor", name: "lendername", index: 1 },
    { label: "Loan Amount", name: "loanamount", index: 1 },
    { label: "Interest Rate", name: "interestrate", index: 2 },
    { label: "Issuing Authority", name: "issuingauth", index: 2 },
    { label: "Closing Date", name: "closingdate", index: 2 },
    { label: "Property Address", name: "propaddress", index: 2 },
    { label: "LTV (Loan-to-Value in %)", name: "ltv", index: 2 },
    { label: "Tenure (In Months)", name: "tenure", index: 3 },
    { label: "Parcel Id", name: "parcelid", index: 3 },
    { label: "Loan Channel Type", name: "channeltype", index: 3 },
    { label: "Loan Delegation Type", name: "delegationtype", index: 3 }
];

const RenderApplicants = ({ index, x }) => {

    let _header = index === 0 ? "Borrower" : x?.applicantIndicatorType;
    let _childheader = index === 0 ? "" : "Co-Applicant's ";
    const OnDummyChange = (e) => { e.preventdefault(); };
    let _borrower = x?.borrower || {};
    let coborrower = x?.coborrower || {};

    return (
        <>
            <div className="contentHeaderBlk">{_header} Details</div>
            <div className="contentarea_pad10">
                <ul className="contentIIIcol">
                    <li className="contentIIIcol">
                        <div className="pipelineContentHead">First Name</div>
                        <div className="pipelineTxtField"><input type="text" readOnly value={_borrower?.firstName} onChange={(e) => OnDummyChange(e)} /></div>
                    </li>
                    <li className="contentIIIcol">
                        <div className="pipelineContentHead">Middle Name</div>
                        <div className="pipelineTxtField"><input type="text" readOnly value={_borrower?.middleName} onChange={(e) => OnDummyChange(e)} /></div>
                    </li>
                    <li className="contentIIIcol">
                        <div className="pipelineContentHead">Last Name</div>
                        <div className="pipelineTxtField"><input type="text" readOnly value={_borrower?.lastName} onChange={(e) => OnDummyChange(e)} /></div>
                    </li>
                </ul>
                <ul className="contentIIIcol">
                    <li className="contentIIIcol">
                        <div className="pipelineContentHead">Phone Type</div>
                        <div className="pipelineTxtField"><input type="text" readOnly value={_borrower?.phoneType} onChange={(e) => OnDummyChange(e)} /></div>
                    </li>
                    <li className="contentIIIcol">
                        <div className="pipelineContentHead">Mobile Phone</div>
                        <div className="pipelineTxtField"><input type="text" readOnly value={_borrower?.mobilePhone} onChange={(e) => OnDummyChange(e)} /></div>
                    </li>
                    <li className="contentIIIcol">
                        <div className="pipelineContentHead">Email</div>
                        <div className="pipelineTxtField"><input type="text" readOnly value={_borrower?.emailAddressText} onChange={(e) => OnDummyChange(e)} /></div>
                    </li>
                </ul>

                {!Helper.IsJSONEmpty(coborrower) && (
                    <>
                        <div className="contentHeaderBlk">{_childheader}Co-Borrower Details</div>
                        <div className="contentarea_pad10">
                            <ul className="contentIIIcol">
                                <li className="contentIIIcol">
                                    <div className="pipelineContentHead">First Name</div>
                                    <div className="pipelineTxtField"><input type="text" readOnly value={coborrower?.firstName} onChange={(e) => OnDummyChange(e)} /></div>
                                </li>
                                <li className="contentIIIcol">
                                    <div className="pipelineContentHead">Middle Name</div>
                                    <div className="pipelineTxtField"><input type="text" readOnly value={coborrower?.middleName} onChange={(e) => OnDummyChange(e)} /></div>
                                </li>
                                <li className="contentIIIcol">
                                    <div className="pipelineContentHead">Last Name</div>
                                    <div className="pipelineTxtField"><input type="text" readOnly value={coborrower?.lastName} onChange={(e) => OnDummyChange(e)} /></div>
                                </li>
                            </ul>
                            <ul className="contentIIIcol">
                                <li className="contentIIIcol">
                                    <div className="pipelineContentHead">Phone Type</div>
                                    <div className="pipelineTxtField"><input type="text" readOnly value={coborrower?.phoneType} onChange={(e) => OnDummyChange(e)} /></div>
                                </li>
                                <li className="contentIIIcol">
                                    <div className="pipelineContentHead">Mobile Phone</div>
                                    <div className="pipelineTxtField"><input type="text" readOnly value={coborrower?.mobilePhone} onChange={(e) => OnDummyChange(e)} /></div>
                                </li>
                                <li className="contentIIIcol">
                                    <div className="pipelineContentHead">Email</div>
                                    <div className="pipelineTxtField"><input type="text" readOnly value={coborrower?.emailAddressText} onChange={(e) => OnDummyChange(e)} /></div>
                                </li>
                            </ul>
                        </div>
                    </>
                )}

            </div>
        </>
    )
}

const RenderGeneralDetails = ({ index, items }) => {
    let list = GeneralDetails.filter((x) => x.index === index);
    return (
        <ul className="contentVcol">
            {list.map((x, index) => (
                <li className="contentVcol" key={index}>
                    <div className="pipelineContentHead">{x.label}</div>
                    <div className="pipelineTxtField"><input type="text"
                        onChange={(e) => {
                            e.preventDefault();
                        }}
                        readOnly value={Helper.GetJsonValue(items, x.name)} /></div>
                </li>
            ))}
        </ul>
    )
}

const Component = () => {

    const [initlized, setInitlized] = React.useState(false);
    const [row, setRow] = React.useState({});
    const [loanInfo, setLoanInfo] = React.useState({});
    const [floanId] = useTimerSession("floanid");

    const FetchResults = async () => {
        global.Busy(true);
        let rslt = await GetLoanDetailsById(floanId);
        if (rslt.status === 100) setRow(rslt.data);

        rslt = await FindLoanById(floanId);
        if (rslt.status === 100) setLoanInfo(rslt.data);

        global.Busy(false);
    };

    if (initlized) {
        setInitlized(false);
        setRow({});
        setLoanInfo({});
        FetchResults();
    }

    React.useEffect(() => {
        setInitlized(true);
    }, []);

    const GetApplicants = () => {
        return (row && row?.stageOne && row?.stageOne?.applicants) || [];
    }

    return (
        <>
            <Container footerType={"full"} showRates={true}>
                <div className="widthfix">
                    <div id="pipelineBlk">
                        <ul className="pgIIcol_leftNavi">
                            <li className="pgIIcol_leftNavi">
                                <ContentLeft form1003={true} id={row?.userid} loanNumber={row?.loanNumber} />
                            </li>
                            <li className="pgIIcol_leftNavi">
                                <div className="pipelineContentBlk">
                                    <div className="pipeline_headblk_border">
                                        <div className="blkName">Details for ID - {row?.loanNumber}</div>
                                    </div>
                                    <ul className="pipelineStage">
                                        <li className="pipelineStage complete">Started</li>
                                        <li className="pipelineStage complete">Registration</li>
                                        <li className="pipelineStage active">Rate Lock</li>
                                        <li className="pipelineStage">Disclosure</li>
                                        <li className="pipelineStage">Submission</li>
                                        <li className="pipelineStage">Approval</li>
                                        <li className="pipelineStage">Processing</li>
                                        <li className="pipelineStage">Closing</li>
                                    </ul>

                                    <div className="contentHeaderBlk">General Details</div>
                                    <div className="contentarea_pad10">
                                        {[1, 2, 3].map((x) => (<RenderGeneralDetails items={loanInfo} index={x} key={x} />))}
                                    </div>
                                    {GetApplicants().map((x, index) => (
                                        <RenderApplicants x={x} index={index} key={index} />
                                    ))}
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </Container>

        </>
    );
};

export default Component;
