import { React, Link } from "../../common";

const FooterFull = () => {
  return (
    <div className="footerBlk">
      <div className="footerInpage">
        i3 Lending Inc &copy; {new Date().getFullYear()} | All rights reserved.
      </div>
    </div>
  );
};

const FooterSmall = () => {
  return (

    <div className="footerBlk">
      <ul className="footerLogin">
        <li className="footerLogin"><Link to="/privacy">Privacy Policy</Link>  &nbsp; | &nbsp;  <Link to="/terms">Terms of Use</Link>  &nbsp; | &nbsp;  Company NMLS: 1020884</li>
        <li className="footerLogin"> i3 Lending Inc &copy; {new Date().getFullYear()}&nbsp; | &nbsp;All rights reserved.</li>
      </ul>
    </div>
  );
};

const Component = ({ type }) => {
  return type ? <FooterFull /> : <FooterSmall />;
};

export default Component;
