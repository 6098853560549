import { React, Link } from "../../../common";

const Component = () => {
  return (
    <li className="pgIIcol">
      <div className="dashboardShadowBlk">
        <div className="dash_LO_Blk">
          <ul className="dashLoanOverviewHead">
            <li className="dashLoanOverviewHead">Loans Originated</li>
            <li className="dashLoanOverviewHead">Loans Denied</li>
          </ul>

          <ul className="dash_LO_II_Col">
            <li className="dash_LO_II_Col">
              <div className="dashLoanValue">$22.5 M</div>
              <div className="dashLoanValueNote">Loan Origination Value</div>

              <ul className="dashLoanOverviewDesc">
                <li className="dashLoanOverviewDesc">
                  <div className="dashLoanDescValue">50.2k</div>
                  <div className="dashLoanDescValueNote">
                    No. of Loans Originated
                  </div>
                </li>
                <li className="dashLoanOverviewDesc">
                  <div className="dashLoanDescValue">$12.0k</div>
                  <div className="dashLoanDescValueNote">
                    Average Loan Value
                  </div>
                </li>
              </ul>
            </li>
            <li className="dash_LO_II_Col">
              <div className="dashLoanValue">$52.3 M</div>
              <div className="dashLoanValueNote">Value of Loans Denied</div>

              <ul className="dashLoanOverviewDesc">
                <li className="dashLoanOverviewDesc">
                  <div className="dashLoanDescValue">48.4k</div>
                  <div className="dashLoanDescValueNote">
                    No. of Loans Denied
                  </div>
                </li>
                <li className="dashLoanOverviewDesc">
                  <div className="dashLoanDescValue">$45.9M</div>
                  <div className="dashLoanDescValueNote">
                    Applicant Withdrew
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>

      {/* <div className="dashboardShadowBlk"> */}
      <div className="dashboard_headblk" style={{ display: "none" }}>
        <div className="blkName">Loan Overview</div>
        <div className="dashboard_head_right" style={{ display: "none" }}>
          <div className="dashboard_rightVerBlock">
            <Link to="#">
              <div className="dashMoreIcon" title="View All"></div>
            </Link>
          </div>
          <div className="dashboard_rightVerBlock">
            <Link to="#">
              <div className="dashNoteIcon" title="Create New"></div>
            </Link>
          </div>
        </div>
      </div>
      <div className="noContent" style={{ display: "none" }}>
        No Data Available
      </div>
      <div className="dashLoanOverviewBlk" style={{ display: "none" }}>
        <ul className="dashLoanOverviewHead">
          <li className="dashLoanOverviewHead">Loans Originated</li>
          <li className="dashLoanOverviewHead">Loans Denied</li>
        </ul>
        <ul className="dashLoanOverviewValue">
          <li className="dashLoanOverviewValue">
            <div className="dashLoanValue">$22.5 M</div>
            <div className="dashLoanValueNote">Loan Origination Value</div>
          </li>
          <li className="dashLoanOverviewValue">
            <div className="dashLoanValue">$52.3 M</div>
            <div className="dashLoanValueNote">Value of Loans Denied</div>
          </li>
        </ul>
        <ul className="dashLoanOverviewDescValue">
          <li className="dashLoanOverviewValue">
            <ul className="dashLoanOverviewDesc">
              <li className="dashLoanOverviewDesc">
                <div className="dashLoanDescValue">50.2k</div>
                <div className="dashLoanDescValueNote">
                  No. of Loans Originated
                </div>
              </li>
              <li className="dashLoanOverviewDesc">
                <div className="dashLoanDescValue">$12.0k</div>
                <div className="dashLoanDescValueNote">Average Loan Value</div>
              </li>
            </ul>
          </li>
          <li className="dashLoanOverviewValue">
            <ul className="dashLoanOverviewDesc">
              <li className="dashLoanOverviewDesc">
                <div className="dashLoanDescValue">48.4k</div>
                <div className="dashLoanDescValueNote">No. of Loans Denied</div>
              </li>
              <li className="dashLoanOverviewDesc">
                <div className="dashLoanDescValue">$45.9M</div>
                <div className="dashLoanDescValueNote">Applicant Withdrew</div>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      {/* </div> */}
    </li>
  );
};

export default Component;
