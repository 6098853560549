var fn = {};

fn.Store = (key, value) => {
  localStorage.setItem(key, value);
};

fn.StoreAsync = async (key, value) => {
  return new Promise(async (resolve) => {
    localStorage.setItem(key, value);
    return resolve(true);
  });
};

fn.StoreJSON = async (key, value) => {
  return new Promise(async (resolve) => {
    localStorage.setItem(key, JSON.stringify(value));
    return resolve(true);
  });
};

fn.StoreAsyncJSON = async (key, value) => {
  return new Promise(async (resolve) => {
    localStorage.setItem(key, JSON.stringify(value));
    return resolve(true);
  });
};

fn.Retrieve = async (key) => {
  return new Promise(async (resolve) => {
    return resolve(localStorage.getItem(key));
  });
};

fn.Retrieve2 = (key) => {
  return localStorage.getItem(key);
};

fn.RetrieveJSONAsync = async (key) => {
  return new Promise(async (resolve) => {
    let data = localStorage.getItem(key);
    if (data === undefined || data === null) return resolve({});
    return resolve(JSON.parse(data));
  });
};

fn.RetrieveJSON = (key) => {
  let data = localStorage.getItem(key);
  if (data === undefined || data === null) return {};
  return JSON.parse(data);
};

fn.Remove = (key) => {
  return localStorage.removeItem(key);
};

fn.RemoveAsync = async (key) => {
  return new Promise(async (resolve) => {
    return resolve(localStorage.removeItem(key));
  });
};

export default fn;
