import { React, Link } from "../../../common";

const Component = () => {
  return (
    <li className="pgIIcol_40by60">
      <div className="dashNotifyBlk">
        <div className="dashNotify_headblk">
          <div className="dashNotify_blkName">Notifications</div>
          <div className="dashNotify_head_right">
            <div className="dashNotify_rightVerBlock">
              <Link to="#">
                <div
                  className="dashMoreWhiteIcon"
                  title="View All Notifications"
                ></div>
              </Link>
            </div>
            <div className="dashNotify_rightVerBlock">
              <div className="dashLabel_chkbox">
                <input type="checkbox" id="cb_address" />
                <label htmlFor="cb_address">Only Unread</label>
              </div>
            </div>
          </div>
        </div>

        <ul className="dashNotifyUserMsg">
          <li className="dashNotifyUserMsg" title="System">
            S
          </li>
          <li className="dashNotifyUserMsg">
            <ul className="dashNotications">
              <li className="dashNotications">
                <ul className="dashNotificationMsg">
                  <li className="dashNotificationMsg">
                    <Link to="#">Approval Pending</Link>
                  </li>
                  <li className="dashNotificationMsg">2 min ago</li>
                  <li className="dashNotificationMsg">
                    reviewing, authorizing and recommending loans for reviewing,
                    authorizing and recommending loans for recommending loans
                    for
                  </li>
                </ul>
              </li>
              <li className="dashNotications">
                <div className="dashUnreadIcon"></div>
              </li>
            </ul>
          </li>

          <li className="dashNotifyUserMsg" title="Preetham">
            P
          </li>
          <li className="dashNotifyUserMsg">
            <ul className="dashNotications">
              <li className="dashNotications">
                <ul className="dashNotificationMsg">
                  <li className="dashNotificationMsg">
                    <Link to="#">Filling 1003 form</Link>
                  </li>
                  <li className="dashNotificationMsg">32 min ago</li>
                  <li className="dashNotificationMsg">
                    reviewing, authorizing and recommending loans
                  </li>
                </ul>
              </li>
              <li className="dashNotications">
                <div className="dashUnreadIcon"></div>
              </li>
            </ul>
          </li>

          <li className="dashNotifyUserMsg" title="Girish">
            G
          </li>
          <li className="dashNotifyUserMsg">
            <ul className="dashNotications">
              <li className="dashNotications">
                <ul className="dashNotificationMsg">
                  <li className="dashNotificationMsg">
                    <Link to="#">Application submitted successfully</Link>
                  </li>
                  <li className="dashNotificationMsg">Feb 14, 2023</li>
                  <li className="dashNotificationMsg">
                    reviewing, authorizing and recommending loans
                  </li>
                </ul>
              </li>
              <li className="dashNotications">
                <div
                  className="dashUnreadIcon"
                  style={{ display: "none" }}
                ></div>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </li>
  );
};

export default Component;
