import { React, Container, useTimerSession, useParams } from "../../../common";
import { ContentLeft } from "../../components";
import { GetISPDocuments } from "../../../services/loanApi";
import { GetDocumentUrl } from "../../../services/documentApi";

const Component = () => {
    const [initlized, setInitlized] = React.useState(false);
    const [docList, setDocList] = React.useState([]);
    const [selectedIndex, setSelectedIndex] = React.useState(-1);
    const [selectedDoc, setSelectedDoc] = React.useState({});
    const [pdfStream, setPdfStream] = React.useState(null);
    const [dloanId] = useTimerSession("dloanid");
    const [duserId] = useTimerSession("duserid");
    const [dloannum] = useTimerSession("dloannum");
    let { id } = useParams();

    const FetchResults = async () => {
        global.Busy(true);
        setDocList([]);
        let rslt = await GetISPDocuments(dloanId, -100);
        if (rslt.status === 100) {
            setDocList(rslt.data);
        }
        global.Busy(false);
    };

    if (initlized) {
        setInitlized(false);
        FetchResults();
    }

    React.useEffect(() => {
        setInitlized(true);
    }, [dloanId]);

    React.useEffect(() => {
        if (docList && docList.length > 0) {
            setSelectedIndex(id);
        }
        if (selectedIndex > -1) {
            setSelectedDoc({});
            let _tmp = docList[selectedIndex];
            const fn = async (e) => {
                let _obj = { doc: e.blobname, type: "AWS" }
                global.Busy(true);
                let rslt = await GetDocumentUrl(_obj);
                global.Busy(false);
                if (rslt.status === 100) {
                    setPdfStream(JSON.parse(rslt.data));
                }
                console.log(_tmp);
                setSelectedDoc(_tmp);
            };
            fn(_tmp)
        }
    }, [selectedIndex, docList, id]);

    const DocumentCount = () => {
        return (docList && docList?.length - 1) || 0;
    }

    const MoveDocument = (e, isNext) => {
        e.preventDefault();
        let _index = selectedIndex
        if (isNext) _index++;
        if (!isNext) _index--;
        setSelectedIndex(_index);
    }

    return (
        <>
            <Container footerType={"full"} showRates={true}>
                <div className="widthfix">
                    <div id="documentBlk">
                        <ul className="pgIIcol_leftNavi">
                            <li className="pgIIcol_leftNavi">
                                <ContentLeft id={duserId} loanNumber={dloannum} />
                            </li>
                            <li className="pgIIcol_leftNavi">
                                <div className="documentContentBlk">
                                    <div className="document_headblk">
                                        <div className="blkName">Documents - {dloannum}</div>
                                        <div className="head_right">
                                            {/* <div className="rightVerBlock">
                                                    <Link to="/leadspredoclist"><input type="button" className="rightButton" value="&#9664; Back" /></Link>
                                                </div> */}
                                            <div className="rightVerBlock">
                                                {parseInt(selectedIndex) < 1 && <input type="button" className="rightButton" value="&#10094; &nbsp;Prev Doc" disabled />}
                                                {parseInt(selectedIndex) > 0 &&
                                                    <input type="button" className="rightButton"
                                                        value="&#10094; &nbsp;Prev Doc"
                                                        onClick={(e) => MoveDocument(e, false)}
                                                    />}
                                            </div>
                                            <div className="rightVerBlock">
                                                {docList && (
                                                    parseInt(selectedIndex) === DocumentCount() && <input type="button" className="rightButton" value="Next Doc &nbsp;&#10095;" disabled />
                                                )}
                                                {docList && (
                                                    parseInt(selectedIndex) < DocumentCount() &&
                                                    <input type="button" className="rightButton"
                                                        value="Next Doc &nbsp;&#10095;"
                                                        onClick={(e) => MoveDocument(e, true)}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <ul className="documentView">
                                        <li className="documentView_verBlk">Document Type:</li>
                                        <li className="documentView_verBlk">{selectedDoc?.docinfo}</li>
                                        <li className="documentView_verBlk">Document Info:</li>
                                        <li className="documentView_verBlk">{selectedDoc?.docinfo}</li>
                                        <li className="documentView">
                                            {/* display the document here */}
                                            {pdfStream && (
                                                <object
                                                    data={pdfStream}
                                                    style={{ height: "100%", width: "100%" }}
                                                    type="application/pdf"
                                                >
                                                    <div>No PDF viewer available</div>
                                                </object>
                                            )}
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </Container>

        </>
    );
};

export default Component;
