import {
  React, Link, useNavigate,
  useTimerSession, useLocation, Session
} from "../../common";

const docMnu = ["/docview", "/uploaddoc", "/doclist"];
const ratesMnu = ["/mortgagerates"];
const pipeMnu = ["/pipe", "/piped"];

const IsActive = (mnu, icon) => {
  let loc = useLocation().pathname;
  if (loc.startsWith("/lead")) loc = "/lead";
  if (docMnu.findIndex((x) => loc.startsWith(x)) > -1) { loc = "/documents"; }
  if (ratesMnu.findIndex((x) => loc.startsWith(x)) > -1) { loc = "/rates"; }
  if (pipeMnu.findIndex((x) => loc.startsWith(x)) > -1) { loc = "/pipeline"; }

  const isActiveMenu = mnu === loc;
  if (icon) {
    return isActiveMenu ? `${icon} active` : icon;
  }
  return isActiveMenu ? "dropbtn active" : "dropbtn";
};

const LoggedOutHeader = () => {
  return (
    <>
      <header id="headerLogin">
        <div className="widthfix">
          <div className="logo">
            <Link to="/">
              <div className="i3LendingLogo logoPosition"></div>
            </Link>
          </div>
          <div className="loginHeaderTopRight">
            <div className="callus">Call us at</div>
            <div className="phNo">(888) 975-1374</div>
          </div>
        </div>
      </header>
    </>
  );
};

const LoggedInAccountHeader = () => {
  const [fullName, setFullName] = React.useState("Hello, Sign In");
  const NavigateTo = useNavigate();

  React.useEffect(() => {
    const fn = async () => {
      let user = await Session.RetrieveJSONAsync("user");
      setFullName(user.name);
    };
    fn();
  }, []);

  const OnSignOutClicked = async (e) => {
    e.preventDefault();
    await Session.Remove("user");
    await Session.Remove("userToken");
    await Session.Remove("status");
    NavigateTo("/");
  };

  return (
    <>
      <div className="navRight">
        <Link to="#" title="Settings"><div className="settingicon"></div></Link>
        <Link to="#" title="Tasks"><div className="alerticon"></div></Link>
        <ul className="rightAccountBlk">
          <li className="rightAccountBlk" title={fullName}>{fullName}</li>
          <li className="rightAccountBlk">
            <div className="accountnav_mini" id="myAccountnav">
              <div className="dropdownAcc_mini">
                <button className="dropbtnAcc_mini">NMLS ID: 1234567 <div className="arrow_down_mini"></div></button>
                <div className="dropdown-content_mini">
                  <Link to="/profile"><div className="profileIcon"></div>Profile</Link>
                  <Link to="/profile"><div className="pswdIcon"></div>Update Password</Link>
                  <Link to="#" onClick={(e) => OnSignOutClicked(e)}><div className="logoutIcon"></div>Logout</Link>
                </div>
              </div>
              <Link to="#" className="icon" onClick={() => window.accFunction()}></Link>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

const LoggedInHeaderMenuItems = () => {

  const NavigateTo = useNavigate();

  const OnNavigateClicked = (e, link) => {
    e.preventDefault();
    NavigateTo(link);
  }

  return (
    <>
      <div className="navBlock">
        <div className="topnav" id="myTopnav">
          <Link title="Logo" to="/"><div className="logoPosResponsive i3LendingLogoWhite"></div></Link>
          <div className="dropdown">
            <button
              onClick={(e) => OnNavigateClicked(e, "/dashboard")}
              className={IsActive("/dashboard")}>
              <div className={IsActive("/dashboard", "dashboardIcon")}></div>Dashboard
            </button>
          </div>

          <div className="dropdown">
            <button
              onClick={(e) => OnNavigateClicked(e, "/lead")}
              className={IsActive("/lead")}>
              <div className={IsActive("/lead", "leadsIcon")}></div>Leads
            </button>
          </div>

          <div className="dropdown">
            <button
              onClick={(e) => OnNavigateClicked(e, "/pipe")}
              className={IsActive("/pipeline")}>
              <div className={IsActive("/pipeline", "pipelineIcon")}></div>Pipeline
            </button>
          </div>

          <div className="dropdown">
            <button className={IsActive("/documents")}>
              <div className={IsActive("/documents", "documentIcon")}></div>Documents <div className="arrow_down_topnav"></div>
            </button>
            <div className="dropdown-content">
              <Link to="#" onClick={(e) => OnNavigateClicked(e, "/doclist")}>View Documents</Link>
              <Link to="#" onClick={(e) => OnNavigateClicked(e, "/uploaddoc")}>Upload Documents</Link>
            </div>
          </div>

          <div className="dropdown">
            <button
              onClick={(e) => OnNavigateClicked(e, "/reports")}
              className={IsActive("/reports")}>
              <div className={IsActive("/reports", "reportIcon")}></div>Reports
            </button>
          </div>

          <div className="dropdown">
            <button
              onClick={(e) => OnNavigateClicked(e, "/contacts")}
              className={IsActive("/contacts")}>
              <div className={IsActive("/contacts", "contactIcon")}></div>Contacts
            </button>
          </div>

          <div className="dropdown">
            <button
              onClick={(e) => OnNavigateClicked(e, "/campaign")}
              className={IsActive("/campaign")}>
              <div className={IsActive("/campaign", "campaignIcon")}></div>Campaign
            </button>
          </div>

          <div className="dropdown">
            <button className={IsActive("/rates")}><div className={IsActive("/rates", "moreIcon")}></div>More <div className="arrow_down_topnav"></div></button>
            <div className="dropdown-content">
              <Link to="#" onClick={(e) => OnNavigateClicked(e, "/mortgagerates")}>Mortgage Rates</Link>
            </div>
          </div>

          <Link to="#" title="Logout" className="icon" onClick={() => window.myFunction()}></Link>
        </div>
      </div>
    </>
  );
};

const LoggedInHeader = () => {
  return (
    <>
      <header id="headerInpage">
        <LoggedInAccountHeader />
        <LoggedInHeaderMenuItems />
      </header>
    </>
  );
};

const Component = () => {
  const [userToken] = useTimerSession("userToken");
  return (
    <>
      {userToken ? <LoggedInHeader /> : <LoggedOutHeader />}
    </>
  );
};

export default Component;
