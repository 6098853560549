import { React, Link } from "../../../common";

const Component = () => {
  return (
    <li className="pgIIcol">
      <div className="dashboardShadowBlk" style={{ display: "none" }}>
        <div className="dashboard_headblk">
          <div className="blkName">Messages</div>
          <div className="dashboard_head_right">
            <div className="dashboard_rightVerBlock">
              <Link to="#">
                <div className="dashMoreIcon" title="View All Messages"></div>
              </Link>
            </div>
            <div className="dashboard_rightVerBlock">
              <Link to="#">
                <div className="dashNoteIcon" title="Write New Message"></div>
              </Link>
            </div>
          </div>
        </div>
        <div className="noContent" style={{ display: "none" }}>
          No Messages Available
        </div>
        <ul className="dashMsgUserBlk">
          <li className="dashMsgUserBlk" title="Bhaskar">
            B
          </li>
          <li className="dashMsgUserBlk">
            <ul className="dashMessage">
              <li className="dashMessage">
                <ul className="dashMsgContent">
                  <li className="dashMsgContent">
                    <Link to="#">Document Uploaded</Link>
                  </li>
                  <li className="dashMsgContent">12 min ago</li>
                  <li className="dashMsgContent">
                    Docuents uploaded successfully. Pls check
                  </li>
                </ul>
              </li>
              <li className="dashMessage">
                <div className="dashUnreadIcon"></div>
              </li>
            </ul>
          </li>

          <li className="dashMsgUserBlk" title="Preetham">
            P
          </li>
          <li className="dashMsgUserBlk">
            <ul className="dashMessage">
              <li className="dashMessage">
                <ul className="dashMsgContent">
                  <li className="dashMsgContent">
                    <Link to="#">Need help on filling</Link>
                  </li>
                  <li className="dashMsgContent">43 min ago</li>
                  <li className="dashMsgContent">
                    Please help filling the declarations page for co-borrower
                  </li>
                </ul>
              </li>
              <li className="dashMessage">
                <div className="dashUnreadIcon"></div>
              </li>
            </ul>
          </li>

          <li className="dashMsgUserBlk" title="Girish">
            G
          </li>
          <li className="dashMsgUserBlk">
            <ul className="dashMessage">
              <li className="dashMessage">
                <ul className="dashMsgContent">
                  <li className="dashMsgContent">
                    <Link to="#">Time to close</Link>
                  </li>
                  <li className="dashMsgContent">Jan 10, 2023</li>
                  <li className="dashMsgContent">
                    What is the status of my loan application
                  </li>
                </ul>
              </li>
              <li className="dashMessage">
                <div
                  className="dashUnreadIcon"
                  style={{ display: "none" }}
                ></div>
              </li>
            </ul>
          </li>
        </ul>
      </div>

      <div className="dashboardShadowBlk_noPadBot">
        <ul className="dash_LoanStatus_Funded">
          <li className="dash_LoanStatus_Funded">
            <div className="dashboard_headblk">
              <div className="blkName_center">Application Vs Funding</div>
            </div>
            <div className="dash_LoanApplication_graph"></div>
            <ul className="graphDesc_IIcol">
              <li className="graphDesc_IIcol">
                <div className="graphDescTxt">Applications</div>
                <div className="graphColor blue"></div>
              </li>
              <li className="graphDesc_IIcol">
                <div className="graphDescTxt">Funded</div>
                <div className="graphColor red"></div>
              </li>
            </ul>
          </li>

          <li className="dash_LoanStatus_Funded">
            <div className="dashboard_headblk">
              <div className="blkName_center">Loan Status</div>
            </div>
            <div className="dash_LoanStatus_graph"></div>
            <ul className="graphDesc_IVcol">
              <li className="graphDesc_IVcol">
                <div className="graphDescTxt">Origination</div>
                <div className="graphColor orange"></div>
              </li>
              <li className="graphDesc_IVcol">
                <div className="graphDescTxt">Processing</div>
                <div className="graphColor green"></div>
              </li>
              <li className="graphDesc_IVcol">
                <div className="graphDescTxt">Closed</div>
                <div className="graphColor red"></div>
              </li>
              <li className="graphDesc_IVcol">
                <div className="graphDescTxt">Funded</div>
                <div className="graphColor blue"></div>
              </li>
            </ul>
          </li>
        </ul>
      </div>

      <div className="dashboardShadowBlk" style={{ display: "none" }}>
        <ul className="contentIIcol">
          <li className="contentIIcol">
            <div className="dashboard_headblk">
              <div className="blkName_center">Loan Status</div>
            </div>
            <div className="dash_LO_Status">
              <div className="dash_LO_graph"></div>
              <ul className="graphDesc">
                <li className="graphDesc">
                  <div className="graphDescTxt">Origination</div>
                  <div className="graphColor orange"></div>
                </li>
                <li className="graphDesc">
                  <div className="graphDescTxt">Processing</div>
                  <div className="graphColor blue"></div>
                </li>
                <li className="graphDesc">
                  <div className="graphDescTxt">Closed</div>
                  <div className="graphColor green"></div>
                </li>
                <li className="graphDesc">
                  <div className="graphDescTxt">Funded</div>
                  <div className="graphColor red"></div>
                </li>
              </ul>
            </div>
          </li>
          <li className="contentIIcol">
            <div className="dashboard_headblk">
              <div className="blkName_center">Locks Vs Funded</div>
            </div>
          </li>
        </ul>
      </div>
    </li>
  );
};

export default Component;
