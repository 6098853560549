import API from "./api";
import session from "../session";

const GetProfile = async () => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`/profile`, {
      headers: token,
    });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

const DeleteProfileDoc = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.post(`/profile/delete`, input, {
      headers: token,
    });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

const SetProfile = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.post(`/profile`, input, {
      headers: token,
    });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

const ChangePassword = async (input) => {
  try {
    let token = await session.GetHeader();
    const res = await API.post("profile/password", input, {
      headers: token,
    });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

const GetShortUserProfile = async (id) => {
  let url = "profile/user";
  if (id) url = `profile/user/${id}`;
  try {
    let token = await session.GetHeader();
    const res = await API.get(url, { headers: token });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

export { GetProfile, SetProfile, DeleteProfileDoc, ChangePassword, GetShortUserProfile };
