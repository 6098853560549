import { React, Helper } from "../../../common";
import { AddLoan, GetShortList, GetLoan, GetLoadIdByMappingId } from "../../../services/loanApi";
import { RenderDeclarations, RenderDemographicInfo, RenderMilitaryService } from "./childs";
import Container from "./container1003";
import { DemographicInfo, MilitaryServiceInfo } from "../help";

const races = ["hmdaAmericanIndianIndicator", "hmdaAsianIndicator", "hmdaAfricanAmericanIndicator",
  "hmdaPacificIslanderIndicator", "hmdaWhiteIndicator", "hmdaRaceDoNotWishProvideIndicator"];
const sexTypes = ["hmdaGendertypeFemaleIndicator", "hmdaGendertypeMaleIndicator", "hmdaGendertypeDoNotWishIndicator"];

const AddDefaultObject = (borrowersList) => {
  let _tmp = [];

  borrowersList.forEach((e) => {
    _tmp.push({
      mapid: e.mapid, type: e.type, coBorrowerIndicator: e.coBorrowerIndicator,
      declarations: { coBorrowerIndicator: e.coBorrowerIndicator, borrower: {}, coborrower: {} },
      demographicInfo: { coBorrowerIndicator: e.coBorrowerIndicator, borrower: {}, coborrower: {} },
      militaryService: { coBorrowerIndicator: e.coBorrowerIndicator, borrower: {}, coborrower: {} },
      completed: false
    })
  });

  return _tmp;
}

const Component = ({ formId, approvalId, onTabClicked, noupdate }) => {
  const [initlized, setInitlized] = React.useState(false);
  const [loadData, setLoadData] = React.useState(false);
  const [errors, setErrors] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(null);
  const [loanId, setLoanId] = React.useState(null);
  const [row, setRow] = React.useState({});
  const [state, setState] = React.useState(false);
  const [lastStage, setLastStage] = React.useState(6);
  const stageIndicator = 6;

  const [helpType, setHelpType] = React.useState(null);

  const [borrowersList, setBorrowersList] = React.useState([]);
  const [selectedBorrower, setSelectedBorrower] = React.useState("");
  const [selectedBorrowerIndex, setSelectedBorrowerIndex] = React.useState(0);
  const [hasAccess, setHasAccess] = React.useState(false);

  const GetUserConcents = async () => {
    setHasAccess(false);
    global.Busy(true);
    let rslt = await GetConsentsResponse(loanId);
    if (rslt.status === 100) {
      let _tmp = rslt.data || [];
      _tmp = _tmp.find((x) => x.requestype === '1003')?.requestype;
      if (!Helper.IsNullValue(_tmp)) setHasAccess(true);
    }
    global.Busy(false);
  }

  const FetchResults = async () => {
    global.Busy(true);
    setRow([]);
    setErrors([]);

    if (!Helper.IsNullValue(loanId)) {
      let rslt = await GetShortList(loanId, 2);
      if (rslt.status === 100) {
        let _data = rslt.data;
        Helper.SortBy(_data, 'mapid');
        setBorrowersList(_data);
      }
    }

    global.Busy(false);
  };

  const ContainsKey = (list, mapid) => {
    return list.findIndex((z) => z === mapid) > -1;
  }

  const FetchData = async () => {
    setRow([]);
    let stageSix, rslt;
    global.Busy(true);
    rslt = await GetLoan(stageIndicator, loanId);
    if (rslt.status === 100) {
      let _data = rslt.data;
      setLastStage(_data.stageIndicator);
      stageSix = _data.stageSix.applications;
    }

    let _tmpList, mapList;
    if (Helper.IsNullValue(stageSix) || stageSix.length === 0) {
      stageSix = AddDefaultObject(borrowersList);
    } else {
      mapList = stageSix.map((x) => x.mapid);
      _tmpList = borrowersList.filter((x) => !ContainsKey(mapList, x.mapid));
      let missingList = AddDefaultObject(_tmpList);
      let finalObj = stageSix.concat(missingList);
      stageSix = finalObj;
    }

    _tmpList = stageSix.filter((z) => z.completed).map((x) => x.mapid);
    mapList = borrowersList;
    mapList.forEach((x) => { x.completed = false; x.disabled = true; });
    mapList.forEach((x) => {
      x.completed = ContainsKey(_tmpList, x.mapid);
      x.disabled = !x.completed;
    });

    _tmpList = mapList.filter((x) => !x.completed);
    if (_tmpList.length > 0) {
      let idx = mapList.findIndex((x) => x.mapid === _tmpList[0].mapid);
      mapList[idx].completed = true;
      mapList[idx].disabled = false;
    }

    setBorrowersList(mapList);
    setRow(stageSix);

    await GetUserConcents();

    global.Busy(false);

  }

  if (initlized) {
    setInitlized(false);
    setErrors([]);
    setError(null);
    setSuccess(null);
    FetchResults();
  }

  if (loadData) {
    setLoadData(false);
    console.log("Loading Data...");
    FetchData();
  }

  const ValidateRecord = (obj) => {
    return new Promise(async (resolve) => {
      let errRaised = false;
      let borrower, coborrower;
      let coBorrowerIndicator = obj["coBorrowerIndicator"];
      if (!Helper.IsJSONEmpty(obj)) {
        const demoGraphic = obj['demographicInfo'];
        borrower = demoGraphic.borrower;
        coborrower = demoGraphic.coborrower;
        if ((Helper.IsJSONEmpty(borrower) && (coBorrowerIndicator && Helper.IsJSONEmpty(coborrower)))) {
          errRaised = true;
          setError(`Borrower and Coborrower Ethnicity is required`);
        } else if (!Helper.IsJSONEmpty(borrower) && (coBorrowerIndicator && Helper.IsJSONEmpty(coborrower))) {
          errRaised = true;
          setError("Coborrower Ethnicity is required");
        } else if (Helper.IsJSONEmpty(borrower) && (!coBorrowerIndicator || !Helper.IsJSONEmpty(coborrower))) {
          errRaised = true;
          setError("Borrower Ethnicity is required");
        } else {

          let eBorrower = false, eCBorrower = false;
          races.forEach((e) => {
            if (Helper.ToBool(borrower[e])) eBorrower = true;
            if (coBorrowerIndicator && Helper.ToBool(coborrower[e])) eCBorrower = true;
          });

          if (!eBorrower && (coBorrowerIndicator && !eCBorrower)) {
            errRaised = true;
            setError("Borrower and Coborrower Race is required");
          } else if (eBorrower && (coBorrowerIndicator && !eCBorrower)) {
            errRaised = true;
            setError("Coborrower Race is required");
          } else if (!eBorrower && (!coBorrowerIndicator || eCBorrower)) {
            errRaised = true;
            setError("Borrower Race is required");
          }
        }
      }

      if (!errRaised) {
        let eBorrower = false, eCBorrower = false;
        sexTypes.forEach((e) => {
          if (borrower[e] !== undefined) eBorrower = true;
          if (coborrower[e] !== undefined) eCBorrower = true;
        });

        if (!eBorrower && (coBorrowerIndicator && !eCBorrower)) {
          errRaised = true;
          setError("Borrower and Coborrower Gender is required");
        } else if (eBorrower && (coBorrowerIndicator && !eCBorrower)) {
          errRaised = true;
          setError("Coborrower Gender is required");
        } else if (!eBorrower && (!coBorrowerIndicator || eCBorrower)) {
          errRaised = true;
          setError("Borrower Gender is required");
        }
      }

      if (!errRaised) {
        const militaryServ = obj['militaryService'];
        borrower = militaryServ.borrower;
        coborrower = militaryServ.coborrower;
        if (Helper.IsJSONEmpty(borrower) && (coBorrowerIndicator && Helper.IsJSONEmpty(coborrower))) {
          errRaised = true;
          setError("Borrower and Coborrower Did you ever serve, or are you currently serving?");
        } else if (!Helper.IsJSONEmpty(borrower) && (coBorrowerIndicator && Helper.IsJSONEmpty(coborrower))) {
          errRaised = true;
          setError("Coborrower Did you ever serve, or are you currently serving?");
        } else if (Helper.IsJSONEmpty(borrower) && (!coBorrowerIndicator || !Helper.IsJSONEmpty(coborrower))) {
          errRaised = true;
          setError("Borrower Did you ever serve, or are you currently serving?");
        }
      }

      return resolve(errRaised);
    });
  };

  const OnRecordValidate = async () => {
    return new Promise(async (resolve) => {
      setError("");
      setErrors([]);
      setState(!state);
      let obj = row && row[selectedBorrowerIndex];
      let errRaised = await ValidateRecord(obj);
      return resolve(!errRaised);
    });
  };

  const OnUpdateRecord = async (e, saved) => {
    e.preventDefault();
    setState(!state);
    let _status = await OnRecordValidate();
    if (!_status) return;
    setError(null);
    setSuccess(null);

    row._id = loanId;
    row.stageIndicator = stageIndicator;

    let applicants = JSON.parse(JSON.stringify(row));
    const removeItems = ['stageIndicator', 'statusIndicator', '_id'];
    removeItems.forEach(e => {
      delete applicants[e];
    });

    let newObjs = [];
    for (var cur = 0; cur <= selectedBorrowerIndex; cur++) {
      applicants[cur].completed = true;
      newObjs.push(applicants[cur]);
    }

    let newObj = {
      _id: loanId,
      statusIndicator: 1,
      stageIndicator,
      stageSix: { applications: newObjs },
    };

    global.Busy(true);
    let rslt = await AddLoan(newObj);
    global.Busy(false);
    if (rslt.status !== 100) {
      setErrors(rslt.errors);
      setError(rslt.statusText);
      return;
    }

    if (saved) {
      setSuccess(Helper.SavedMessage);
    } else {
      if (selectedBorrowerIndex === borrowersList.length - 1) {
        if (onTabClicked) onTabClicked(7);
      } else {
        MoveNext();
      }
    }
  };

  const OnInputChanged = async (path, value, index) => {
    let _row = row;
    let newRow = Helper.ModifyJsonArrayObject4(_row[selectedBorrowerIndex], path, value);
    _row[selectedBorrowerIndex] = newRow;
    setRow(_row);
    setState(!state);
  };

  const GetArrayObject = (name) => {
    if (!Helper.IsJSONEmpty(row)) {
      let obj = row && row[selectedBorrowerIndex];
      if (!Helper.IsJSONEmpty(obj)) {
        return obj[name];
      }
    }
    return {};
  }

  const OnHelpClicked = (type, e) => {
    setHelpType(e);
    if (type === 1) {
      document.getElementById("info_DemographicInfo").style.display = "block";
    } else if (type === 2) {
      document.getElementById("info_MilitaryServiceInfo").style.display = "block";
    }
  };

  const OnBlurError = (name, val, clear) => {
    setSuccess(null);
    let _errors = [];
    if (errors && errors.length > 0) _errors = errors.filter((x) => x.field !== name);
    if (Helper.IsNullValue(clear)) _errors.push({ field: name, value: val });
    setErrors(_errors);
    setError(val);
  };

  const OnInputClicked = (name) => {
    setSuccess(null);
    let _err = null;
    if (errors && errors.length > 0) {
      let _findIndex = errors.findIndex((x) => x.field === name);
      if (_findIndex > -1) {
        _err = errors[_findIndex].value;
      }
    }
    setError(_err);
  };

  const MoveNext = () => {
    let index = selectedBorrowerIndex;
    let listItems = borrowersList;
    let _row = row;
    if (listItems.length - 1 > index) {
      index++;
      let _item = listItems[index].mapid;
      row[index].completed = true;
      listItems[index].disabled = false;
      setBorrowersList(listItems);
      setSelectedBorrowerIndex(index);
      setSelectedBorrower(_item);
    }
    setRow(_row);
    setState(!state);
  }

  React.useEffect(() => {
    if (borrowersList && borrowersList.length > 0) {
      setLoadData(true);
    }
  }, [borrowersList]);

  const OnBorrowerSelected = (e) => {
    setSelectedBorrowerIndex(e.target.selectedIndex);
    setSelectedBorrower(e.target.value);
    setErrors([]);
    setError(null);
    setSuccess(null);
    setState(!state);
  }

  const OnLinkClicked = (a) => {
    if (onTabClicked) onTabClicked(a);
  }

  React.useEffect(() => {
    if (!Helper.IsNullValue(approvalId)) {
      const fn = async () => {
        let rslt = await GetLoadIdByMappingId(approvalId, 'pre');
        if (rslt.status === 100) {
          setLoanId(rslt.data._id);
        }
      };
      fn();
    }
  }, [approvalId]);

  React.useEffect(() => {
    if (!Helper.IsNullValue(formId)) {
      setLoanId(formId);
    }
  }, [formId]);

  React.useEffect(() => {
    if (!Helper.IsNullValue(loanId)) {
      setInitlized(true);
    }
  }, [loanId]);

  const MoveNextPrev = async (e, prev) => {
    e.preventDefault();
    if (prev) {
      OnLinkClicked(5);
    }
    else {
      OnLinkClicked(3);
    }
  };

  return (
    <>
      <Container selected={6} lastStage={lastStage} onTabClicked={OnLinkClicked}>
        <ul className="selectBorrowerBlk">
          <li className="selectBorrowerBlk">
            <div className="selectPadLeft10">
              <select
                id={'dpBorrower'}
                name={'dpBorrower'}
                value={Helper.ToString(selectedBorrower)}
                onChange={(e) => OnBorrowerSelected(e)}
              >
                {!Helper.IsArrayNull(borrowersList) &&
                  borrowersList.map((x, index) => {
                    return (
                      <option key={index} disabled={x.disabled} value={x['mapid']}>{x['text']}</option>
                    );
                  })}
              </select>
            </div>
          </li>
          <li className="selectBorrowerBlk">&nbsp;</li>
        </ul>

        <form id="stageSix">
          <RenderDeclarations
            item={GetArrayObject('declarations')}
            onInputChanged={OnInputChanged}
            onHelpClicked={OnHelpClicked}
          />

          <RenderDemographicInfo
            item={GetArrayObject('demographicInfo')}
            onInputChanged={OnInputChanged}
            onHelpClicked={OnHelpClicked}
            onBlurError={OnBlurError}
            onInputClicked={OnInputClicked}
            errors={errors}
          />

          <RenderMilitaryService
            item={GetArrayObject('militaryService')}
            onInputChanged={OnInputChanged}
            onHelpClicked={OnHelpClicked}
            onBlurError={OnBlurError}
            onInputClicked={OnInputClicked}
            errors={errors}
          />
        </form>

        <ul className="form_btn_successError_blk">
          <li className="form_btn_steps_label">
            {noupdate && (
              <>
                <input
                  type="button"
                  defaultValue="Prev"
                  className="form_btn_steps"
                  onClick={(e) => MoveNextPrev(e, true)}
                />
                <input
                  type="button"
                  defaultValue="Next"
                  className="form_btn_steps"
                  disabled
                  onClick={(e) => MoveNextPrev(e)}
                />
              </>
            )}
            {!noupdate && (
              <>
                <input
                  type="button"
                  defaultValue="Save & Finish Later"
                  className="form_btn_steps"
                  onClick={(e) => OnUpdateRecord(e, true)}
                />
                <input
                  type="button"
                  defaultValue="Continue"
                  disabled={!hasAccess}
                  className="form_btn_steps"
                  onClick={(e) => OnUpdateRecord(e, false)}
                />
              </>
            )}
          </li>
          {!hasAccess && (
            <li className="form_successError_steps">
              <div className="errormessage">You do not have access to submit.</div>
            </li>
          )}
          {error && (
            <li className="form_successError_steps">
              <div className="errormessage">{error}</div>
            </li>
          )}
          {success && (
            <li className="form_successError_steps">
              <div className="successmessage">{success}</div>
            </li>
          )}
        </ul>
      </Container>
      <DemographicInfo helpType={helpType} />
      <MilitaryServiceInfo helpType={helpType} />
    </>
  );
};

export default Component;
