import { React, Helper, Container, useTimerSession, DropDown2 as DropDown } from "../../../common";
import { GetPreapprovalStage } from "../../../services/preapprovalApi";
import { GetLoanStatuses, SetLoanStatus } from "../../../services/commonApi";
import { ContentLeft } from "../../components";

const loanDetails = [
    { id: 1, label: "First Name", name: "firstName", stage: "stage3.borrower", value: "", row: 4 },
    { id: 2, label: "Last Name", name: "lastName", value: "", stage: "stage3.borrower", row: 4 },
    { id: 3, label: "Date of Birth", name: "birthDate", stage: "stage3.borrower", value: "", row: 4 },
    { id: 31, label: "Email Address", name: "emailAddressText", stage: "stage9", value: "", row: 4 },
    { id: 4, label: "Phone Type", name: "phoneType", stage: "stage3.borrower", value: "", row: 4 },
    { id: 5, label: "Phone Number", name: "mobilePhone", stage: "stage3.borrower", value: "", row: 4 },
    { id: 6, label: "Current Address", name: "addressText", value: "", stage: "stage7.borrower", row: 4 },
    { id: 7, label: "City", name: "addressCity", value: "", stage: "stage7.borrower", row: 4 },
    { id: 8, label: "State", name: "addressState", value: "", stage: "stage7.borrower", row: 4 },
    { id: 9, label: "Zip", name: "postalCode", value: "", stage: "stage7.borrower", row: 4 },
    { id: 10, label: "Mobile Number", name: "mobilePhone", stage: "stage7.borrower", value: "", row: 4 },
    { id: 11, label: "Social Security Number", name: "taxIdentificationIdentifier", stage: "stage7.borrower", value: "", row: 4 },
    { id: 12, label: "Military Service", name: "militaryServiceIndicator", stage: "stage3.borrower", value: "", row: 4 },
    { id: 13, label: "Credit Score", name: "creditScore", stage: "stage7.borrower", value: "", row: 4 },
    { id: 14, label: "Where in the process are you?", name: "processIndicatorText", stage: "stage1", value: "", row: 4 },
    { id: 15, label: "When do you plan on purchasing your property?", name: "propertyPlanIndicatorText", stage: "stage1", value: "", row: 4 },
    { id: 16, label: "How can we help?", name: "helpItemIndicatorText", stage: "stage1", value: "", row: 4 },
    { id: 17, label: "Property Address", name: "propertyAddressText", stage: "stage2", value: "", row: 4 },
    { id: 171, label: "Property City", name: "propertyAddressCity", stage: "stage2", value: "", row: 4 },
    { id: 172, label: "Property State", name: "propertyAddressState", stage: "stage2", value: "", row: 4 },
    { id: 173, label: "Property Postal Code", name: "propertyAddressZip", stage: "stage2", value: "", row: 4 },
    { id: 174, label: "Property Country", name: "propertyAddressCountry", stage: "stage2", value: "", row: 4 },
    { id: 18, label: "Property Type", name: "propertyIndicatorText", stage: "stage2", value: "", row: 4 },
    { id: 19, label: "Property Use", name: "propertyUseIndicatorText", stage: "stage2", value: "", row: 4 },
    { id: 20, label: "Estimated Price", name: "propertyPriceIndicatorText", stage: "stage2", value: "", row: 4 },
    { id: 21, label: "Down payment", name: "propertyDownPayment", stage: "stage2", value: "", row: 4 },
    { id: 22, label: "Mortgage Applied For", name: "propertyMortgageAppliedText", stage: "stage2", value: "", row: 4 },
    { id: 23, label: "Total Annual Gross Income", name: "totalAnnualGrossIncome", stage: "stage4.borrower", value: "", row: 4 },
    { id: 24, label: "Total Monthly Debt Payments", name: "totalMonthlyDebtPayments", stage: "stage4.borrower", value: "", row: 4 },
    { id: 25, label: "Savings Accounts", name: "savingsAccount", value: "", stage: "stage9.borrower", row: 4 },
    { id: 26, label: "Retirement Accounts", name: "retirementAccount", stage: "stage9.borrower", value: "", row: 4 },
    { id: 27, label: "Stock and Bonds", name: "stockAndBondAccounts", value: "", stage: "stage9.borrower", row: 4 },
    { id: 28, label: "Gifts from Relatives", name: "gitfAccounts", value: "", stage: "stage9.borrower", row: 4 },
    { id: 29, label: "Sales of Other Real Estate", name: "realEstateAccounts", value: "", stage: "stage9.borrower", row: 4 },
    { id: 30, label: "Other (Business Account)", name: "otherAccounts", value: "", stage: "stage9.borrower", row: 4 },
];

const GetClassName = (row) => {
    const _columns = ["", "I", "II", "III", "IV", "V"];
    return `content${_columns[row]}col`;
}

const GetValue = (values, stage, name) => {
    if (Helper.IsNull(stage)) {
        return Helper.GetJsonValue(values, `${name}`)
    }
    return Helper.GetJsonValue(values, `${stage}.${name}`);
};

const RenderInputColumns = ({ rowIndex, items, values }) => {
    if (items && items.length > 0) {
        let _filters = items.filter((x) => parseInt(x.row) === parseInt(rowIndex));
        if (_filters && _filters.length > 0) {
            let _columnCSS = GetClassName(rowIndex);
            return (
                <ul className={_columnCSS}>
                    {_filters.map((x) => (
                        <li className={_columnCSS} key={x.id}>
                            <div className="leadContentHead">{x.label}</div>
                            <div className="leadTxtField"><input type="text" readOnly value={GetValue(values, x.stage, x.name)} /></div>
                        </li>
                    ))}
                </ul>);
        }
    }
    return null;
}

const Component = () => {
    const [initlized, setInitlized] = React.useState(false);
    const [approval, setApproval] = React.useState({});
    const [loanStatuses, setLoanStatuses] = React.useState([]);
    const [loanStatusId, setLoanStatusId] = React.useState("0");

    const [ploanId] = useTimerSession("ploanid");
    const [puserId] = useTimerSession("puserid");
    const [ploanRef] = useTimerSession("ploanref");

    React.useEffect(() => {
        setInitlized(true);
    }, []);

    const FetchResults = async () => {
        setLoanStatusId("0");
        global.Busy(true);
        let rslt = await GetPreapprovalStage(ploanId, 0);
        if (rslt.status === 100) {
            let tmp = rslt.data;
            // stage3.borrower.militaryServiceIndicator
            let tmp2 = tmp['stage3']?.['borrower']?.['militaryServiceIndicator'] || "";
            if (!Helper.IsNullValue(tmp2)) {
                if (tmp2.toString().toLowerCase() === 'true') tmp2 = "Yes";
                if (tmp2.toString().toLowerCase() === 'false') tmp2 = "No";
                tmp['stage3']['borrower']['militaryServiceIndicator'] = tmp2;
            }
            setApproval(tmp);
            setLoanStatusId(tmp.actualStatusIndicator);
        }


        rslt = await GetLoanStatuses();
        if (rslt.status === 100) {
            let tmp = rslt.data;
            tmp.splice(0, 0, { _id: "0", displayname: "Status" });
            setLoanStatuses(tmp);
        }
        global.Busy(false);
    };

    const onInputChanged = async (path, value) => {
        setLoanStatusId(value);
    }

    const OnApplyStatusClicked = async (e) => {
        e.preventDefault();

        let _req = {
            type: "PRE",
            _id: ploanId,
            statusid: loanStatusId
        };

        global.Busy(true);
        let rslt = await SetLoanStatus(_req);
        global.Busy(false);
        alert(rslt.statusText);
    }

    if (initlized) {
        setInitlized(false);
        setApproval({});
        FetchResults();
    }

    return (
        <>
            <Container footerType={"full"} showRates={true}>
                <div className="widthfix">
                    <div id="leadsBlk">
                        <ul className="pgIIcol_leftNavi">
                            <li className="pgIIcol_leftNavi">
                                <ContentLeft id={puserId} loanNumber={ploanRef} />
                            </li>
                            <li className="pgIIcol_leftNavi">
                                <div className="leadContentBlk">
                                    <div className="lead_headblk_border">
                                        <div className="blkName">Lead Details for ID - {ploanRef}</div>
                                        <div className="inPgHeaderRight">
                                            <input type="button"
                                                className="inPgHeadRightButton" value="Apply"
                                                disabled={loanStatusId === "0" || loanStatusId === 0}
                                                onClick={(e) => OnApplyStatusClicked(e)}
                                            />
                                        </div>
                                        <div className="inPgHeaderRight">
                                            <div className="filterSelectInPgHead">
                                                <DropDown
                                                    path={`loanStatusId`}
                                                    value={loanStatusId}
                                                    onInputChildChanged={onInputChanged}
                                                    items={loanStatuses}
                                                    displayName={"displayname"}
                                                    displayValue={"_id"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <RenderInputColumns rowIndex={4} items={loanDetails} values={approval} />
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </Container>
        </>
    );
};

export default Component;
