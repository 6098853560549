import { React, Link, Container, useTimerSession, useParams } from "../../../../common";
import { ContentLeft } from "../../../components";
import { GetPreDocuments } from "../../../../services/preapprovalApi";
import { GetDocumentUrl } from "../../../../services/documentApi";

const Component = () => {

    const [initlized, setInitlized] = React.useState(false);
    const [ploanId] = useTimerSession("ploanid");
    const [puserId] = useTimerSession("puserid");
    const [ploanRef] = useTimerSession("ploanref");
    const [docList, setDocList] = React.useState([]);
    const [selectedIndex, setSelectedIndex] = React.useState(-1);
    const [selectedDoc, setSelectedDoc] = React.useState({});
    const [pdfStream, setPdfStream] = React.useState(null);

    let { id } = useParams();

    React.useEffect(() => {
        setInitlized(true);
    }, []);

    const FetchPreResults = async () => {
        global.Busy(true);
        setSelectedDoc({});
        setDocList([]);
        let rslt = await GetPreDocuments(ploanId, 1);
        if (rslt.status === 100) {
            let tmp = rslt.data || [];
            setDocList(tmp);
        }
        setSelectedIndex(id);
        global.Busy(false);
    };

    if (initlized) {
        setInitlized(false);
        setSelectedDoc({});
        FetchPreResults();
    }

    React.useEffect(() => {
        if (selectedIndex > -1) {
            setSelectedDoc({});
            let _tmpList = docList || { list: [] };
            let _tmp = _tmpList.list[selectedIndex];
            const fn = async (e) => {
                let _obj = {
                    doc: e.filename,
                    type: "AWS",
                    entity: "PROFILE"
                }
                global.Busy(true);
                let rslt = await GetDocumentUrl(_obj);
                global.Busy(false);
                if (rslt.status === 100) {
                    setPdfStream(JSON.parse(rslt.data));
                }
                setSelectedDoc(_tmp);
            };
            fn(_tmp)
        }
    }, [selectedIndex, docList]);

    const DocumentCount = () => {
        return (docList && docList?.list && docList?.list.length - 1) || 0;
    }

    const MoveDocument = (e, isNext) => {
        e.preventDefault();
        let _index = selectedIndex
        if (isNext) _index++;
        if (!isNext) _index--;
        setSelectedIndex(_index);
    }

    const OnDownLoadClicked = (pdfStream) => {
        fetch(pdfStream)
            .then((response) => response.blob())
            .then((blob) => {
                const url = URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = "file.pdf";
                document.body.appendChild(a);
                a.click();
                a.remove();
            });
    }

    return (
        <>
            <Container footerType={"full"} showRates={true}>
                <div className="widthfix">
                    <div id="leadsBlk">
                        <ul className="pgIIcol_leftNavi">
                            <li className="pgIIcol_leftNavi">
                                <ContentLeft id={puserId} loanNumber={ploanRef} />
                            </li>
                            <li className="pgIIcol_leftNavi">
                                <div className="leadContentBlk">
                                    <div className="lead_headblk">
                                        <div className="blkName">View Document - {ploanRef}</div>

                                        <div className="head_right">
                                            <input
                                                type="button"
                                                className="rightButton"
                                                value="Download"
                                                onClick={(e) => OnDownLoadClicked(pdfStream)}
                                            />
                                            <div className="rightVerBlock">
                                                <Link to="/leadp"><input type="button" className="rightButton" value="&#9664; Back" /></Link>
                                            </div>
                                            <div className="rightVerBlock">
                                                {parseInt(selectedIndex) < 1 && <input type="button" className="rightButton" value="&#10094; &nbsp;Prev Doc" disabled />}
                                                {parseInt(selectedIndex) > 0 &&
                                                    <input type="button" className="rightButton"
                                                        value="&#10094; &nbsp;Prev Doc"
                                                        onClick={(e) => MoveDocument(e, false)}
                                                    />}
                                            </div>
                                            <div className="rightVerBlock">
                                                {docList && (
                                                    parseInt(selectedIndex) === DocumentCount() && <input type="button" className="rightButton" value="Next Doc &nbsp;&#10095;" disabled />
                                                )}
                                                {docList && (
                                                    parseInt(selectedIndex) < DocumentCount() &&
                                                    <input type="button" className="rightButton"
                                                        value="Next Doc &nbsp;&#10095;"
                                                        onClick={(e) => MoveDocument(e, true)}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <ul className="leadPreDocView">
                                        <li className="leadPreDocView_verBlk">Document Type:</li>
                                        <li className="leadPreDocView_verBlk">{selectedDoc?.doctype}</li>
                                        <li className="leadPreDocView_verBlk">Document Info:</li>
                                        <li className="leadPreDocView_verBlk">{selectedDoc?.docinfo}</li>
                                        <li className="leadPreDocView">
                                            {/* display the document here */}
                                            {pdfStream && (
                                                <object
                                                    data={pdfStream}
                                                    style={{ height: "100%", width: "100%" }}
                                                    type="application/pdf"
                                                >
                                                    <div>No PDF viewer available</div>
                                                </object>
                                            )}
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </Container>
        </>
    );
};

export default Component;
