import { React, Link, useNavigate, Helper, Session } from "../../../common";
import { GetPreapprovalList } from "../../../services/preapprovalApi";

const Component = ({ onAddLeadClicked, submitClicked }) => {

  const [initlized, setInitlized] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const NavigateTo = useNavigate();

  React.useEffect(() => {
    setInitlized(true);
    Session.Remove("puserid");
    Session.Remove("ploanid");
    Session.Remove("loanid");
    Session.Remove("ploanref");
  }, []);

  React.useEffect(() => {
    if (submitClicked) setInitlized(true);
  }, [submitClicked]);

  const FetchResults = async () => {
    global.Busy(true);
    let rslt = await GetPreapprovalList();
    if (rslt.status === 100) {
      let _data = rslt.data;
      Helper.SortByNumber(_data, '_id', true);
      setRows(_data);
    }
    global.Busy(false);
  };

  if (initlized) {
    setInitlized(false);
    setRows([]);
    FetchResults();
  }

  const OnLoadSelected = async (e, id, uid, num) => {
    e.preventDefault();
    await Session.StoreAsync("ploanref", num);
    await Session.StoreAsync("ploanid", id);
    await Session.StoreAsync("puserid", uid);
    NavigateTo("/leadd");
  }

  return (
    <li className="pgIIcol">
      <div className="dashboardShadowBlk">
        <div className="dashboard_headblk">
          <div className="blkName">Leads</div>
          <div className="dashboard_head_right">
            <div className="dashboard_rightVerBlock">
              <Link to="/lead">
                <div className="dashMoreIcon" title="View All"></div>
              </Link>
            </div>
            <div className="dashboard_rightVerBlock">
              <Link to="#" onClick={(e) => onAddLeadClicked(e)}>
                <div className="dashAddIcon" title="Add New"></div>
              </Link>
            </div>
          </div>
        </div>
        {(!rows || rows.length === 0) && (<div className="noContent">No Leads Available</div>)}
        {rows && rows.length > 0 && (
          <div className="dashboardContentBlk">
            <div className="dash_leadListTable_head">
              <table>
                <tbody>
                  <tr>
                    <td>Loan ID</td>
                    <td>Name</td>
                    <td>Email ID</td>
                    <td>Phone</td>
                    <td>Created On</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="dash_leadListTable">
              <table>
                <tbody>
                  {rows && rows.slice(0, 4).map((x) => (
                    <tr key={x._id}>
                      <td><Link to="#" onClick={(e) => OnLoadSelected(e, x._id, x.userid, x.refNumber)} >{x.refNumber}</Link></td>
                      <td>{x.Borrower}</td>
                      <td>{x.email}</td>
                      <td>{x.Phone}</td>
                      <td>{Helper.ToDateFormat(x.DateOn, "YYYY-MM-DD")}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </li>
  );
};

export default Component;
