import { React, TabNavigations } from "../../../common";

const Component = ({ children, selected, lastStage, onTabClicked }) => {
    const OnLinkClicked = (a) => {
        if (onTabClicked) onTabClicked(a);
    }
    return (
        <div className="form1003_pgBlk">
            <TabNavigations selected={selected} lastStage={lastStage} onTabClicked={OnLinkClicked} />
            {children}
        </div>
    );
};

export default Component;