import { React, Helper, Container, useTimerSession } from "../../../common";
import { ContentLeft } from "../../components";
import { GetDocTypes, SetAssignDocTypes, GetAssignDocTypes } from "../../../services/documentApi";
import { GetLoanDetailsById } from "../../../services/loanApi";

const Component = () => {
    const [initlized, setInitlized] = React.useState(false);
    const [state, setState] = React.useState(false);
    const [prow, setPRow] = React.useState({});
    const [row, setRow] = React.useState({ childs: [] });
    const [floanId] = useTimerSession("floanid");

    const FetchPreResults = async () => {
        global.Busy(true);
        let _rowData = {};
        let rslt = await GetAssignDocTypes(floanId);
        if (rslt.status === 100) _rowData = rslt.data;

        if (Helper.IsJSONEmpty(_rowData)) {
            rslt = await GetLoanDetailsById(floanId);
            if (rslt.status === 100) {
                _rowData = {
                    loanNumber: rslt.data.loanNumber,
                    loanreference: rslt.data.loanreference,
                    userid: rslt.data.userid
                }
            }
        }

        rslt = await GetDocTypes(1);
        if (rslt.status === 100) {
            if (rslt.data.length > 0) UpdateRowItems(_rowData, rslt.data);
        }
        global.Busy(false);
    };

    if (initlized) {
        setInitlized(false);
        FetchPreResults();
    }

    const UpdateRowItems = (rowData, list) => {
        let _nRows = rowData.childs || [];
        for (let i = 0; i < list.length; i++) {
            let _item = list[i];
            let _rows = _nRows.length > 0 ? _nRows.filter((x) => parseInt(x.mapid) === parseInt(_item.id)) : [];
            if (!_rows || _rows.length === 0) {
                _nRows.push({ mapid: _item.id, name: _item.name, checked: false })
            }
        }
        _nRows.forEach((item, index) => item.index = index + 1);
        rowData.childs = _nRows;
        setRow(rowData);
        setState(!state);
    }

    const OnInputChanged = (e, index, value) => {
        let _rows = row;
        let _nRows = _rows.childs;
        let _index = _nRows?.findIndex((x) => parseInt(x.index) === index);
        if (_index > -1) {
            _nRows[_index].checked = value;
        }
        _rows.childs = _nRows;
        setRow(_rows);
        setState(!state);
    }

    const GetInputValue = (index) => {
        let _nRows = row.childs;
        let _rows = _nRows?.filter((x) => parseInt(x.index) === index);
        if (_rows && _rows.length > 0) {
            return Helper.ToBool(_rows[0].checked);
        }
        return false;
    }

    const OnDummyChanged = () => { };

    const OnUserInfoLoaded = (e) => {
        let _borrower = Helper.GetStringJoin(e, ['first_name', 'last_name']);
        let tmp = {
            _id: floanId,
            Borrower: _borrower,
            userid: e?.id,
            refNumber: row?.loanreference
        };
        setPRow(tmp);
    };

    const OnSubmitClicked = async (e) => {
        e.preventDefault();
        let _row = row;
        _row.mapid = prow._id;
        _row.userid = prow.userid;
        _row.refNumber = prow.refNumber;
        _row.Borrower = prow?.Borrower;
        global.Busy(true);
        let rslt = await SetAssignDocTypes(row);
        global.Busy(false);
        if (rslt.status === 100) {
            _row._id = rslt.data._id;
            setRow(_row);
        }
        setState(!state);
    };

    React.useEffect(() => {
        if (!Helper.IsNullValue(floanId)) setInitlized(true);
    }, [floanId]);

    return (
        <>
            <Container footerType={"full"} showRates={true}>
                <div className="widthfix">
                    <div id="leadsBlk">
                        <ul className="pgIIcol_leftNavi">
                            <li className="pgIIcol_leftNavi">
                                <ContentLeft form1003={true} id={row?.userid} loanNumber={row?.loanNumber} onUserInfoLoaded={OnUserInfoLoaded} />
                            </li>
                            <li className="pgIIcol_leftNavi">
                                <div className="leadContentBlk">
                                    <div className="lead_headblk_border">
                                        <div className="blkName">Lead Details for ID - {row?.loanNumber}</div>
                                    </div>
                                    <ul className="contentIIIcol">
                                        {row && row.childs && row.childs.map((x, index) => {
                                            return (
                                                <li key={index} className="contentIIIcol">
                                                    <div className="lead_chkboxLabel">
                                                        <input
                                                            type="checkbox"
                                                            name={`lead_doc_${x.index}`}
                                                            id={`lead_doc_${x.index}`}
                                                            checked={GetInputValue(x.index)}
                                                            onChange={() => OnDummyChanged()}
                                                            onClick={(e) => OnInputChanged(e, x.index, e.target.checked)}
                                                        />
                                                        <label htmlFor={`lead_doc_${x.index}`}>{x.name}</label>
                                                    </div>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                    <ul className="contentIcol">
                                        <li className="contentIcol">
                                            <div>Comments</div>
                                            <input type="text" />
                                        </li>
                                    </ul>
                                    <ul className="lead_btn_blk">
                                        <li className="lead_btn_blk">
                                            <input type="button" value="Submit" className="lead_btn" onClick={(e) => OnSubmitClicked(e)} />
                                            <input type="button" value="Cancel" className="lead_btn" />
                                        </li>
                                        <li className="lead_btn_successError" style={{ display: "none" }}>
                                            <div className="errormessage">You have some errors. Please fix and proceed.</div>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </Container>
        </>
    );
};

export default Component;
