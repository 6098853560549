import {
  React,
  Link,
  useNavigate,
  Session,
  Storage,
  Helper,
  Container,
} from "../../common";
import { SignIn } from "../../services/authApi";

const Component = () => {
  const [email, setEmail] = React.useState("");
  const [loginKey, setLoginKey] = React.useState("");
  const [error, setError] = React.useState(null);
  const [scope, setSope] = React.useState(null);
  const [remember, setRemember] = React.useState(false);
  const [received, setReceived] = React.useState(false);
  const NavigateTo = useNavigate();

  React.useEffect(() => {
    setError(null);
    setReceived(false);
    setRemember(false);
    setSope(null);
    setLoginKey("");
    setEmail("");
  }, []);

  const OnSignInClicked = async (e, type) => {
    e.preventDefault();
    setError(null);
    if (Helper.IsNullValue(email) || !Helper.IsEmailValid(email)) {
      setError("Enter valid email");
      return;
    }

    if (type === "OTP") {
      let row = { email: email, scope: type.toLowerCase(), apptype: "lend" };
      global.Busy(true);
      let rslt = await SignIn(row);
      global.Busy(false);
      if (rslt.status !== 100) {
        setError(rslt.statusText);
        return;
      }
    }
    setSope(type);
    setReceived(true);
  };

  const OnInputChanged = (name, value) => {
    if (name === "email") {
      setEmail(value);
    } else if (name === "key") {
      setLoginKey(value);
    }
  };

  const OnVericyClicked = async (e) => {
    e.preventDefault();
    setError(null);
    if (Helper.IsNullValue(email) || !Helper.IsEmailValid(email)) {
      setError("Enter valid email");
      return;
    }

    if (Helper.IsNullValue(loginKey)) {
      if (scope === "OTP") {
        setError("Enter Valid OTP");
      } else if (scope === "EMAIL") {
        setError("Enter Valid Password");
      }
      return;
    }

    let row = { email: email, scope: scope.toLowerCase(), apptype: "lend" };

    if (scope === "OTP") {
      row.otp = loginKey;
    } else if (scope === "EMAIL") {
      row.password = loginKey;
    }

    global.Busy(true);
    let rslt = await SignIn(row);
    global.Busy(false);
    if (rslt.status !== 100) {
      setError(rslt.statusText);
      return;
    }
    rslt = rslt.data;

    if (parseInt(rslt.data.typeindex) !== 4) {
      setError('You are not authorize to access');
      return;
    }

    await Session.StoreJSONAsync("status", rslt.data.status);
    await Session.StoreJSONAsync("user", rslt.data);
    await Session.StoreAsync("userToken", rslt.token);

    if (remember) {
      let data = {
        remember: true,
        value: email,
      };
      await Storage.StoreAsyncJSON("login", data);
    }

    NavigateTo("/lead");
  };

  const OnDummyChange = (e) => { };

  return (
    <>
      <Container>
        <div id="container">
          <div id="content">
            <div className="loginBlk">
              <ul className="loginContentBlk">
                <li className="loginLeft">
                  <ul className="loginLeftHorBlk">
                    <li className="loginLeftHorBlk"></li>
                    <li className="loginLeftHorBlk">
                      <div className="loginHeadText">Loan Officer Portal</div>
                      <div className="loginDivider"></div>
                      <div className="loginSupportText">
                        A place for the Loan Officers to combine POS and LOS,
                        streamline their business and serve more clients
                      </div>
                    </li>
                    <li className="loginLeftHorBlk"></li>
                  </ul>
                </li>
                <li className="loginRight">
                  <div className="signIn_head">Sign In</div>
                  <div className="signIn_noAcc">
                    &nbsp;
                    {/* Don't have an account? <Link to="#">Join Us</Link> */}
                  </div>
                  <ul className="signIn_II_col">
                    <li className="signIn_II_col">
                      <div className="signIn_Q">Email Address</div>
                    </li>
                    <li className="signIn_II_col">
                      <div className="signIn_note">
                        <div className="rememberMe_Check">
                          <input
                            type="checkbox"
                            id="cb_remember"
                            checked={remember}
                            onChange={(e) => OnDummyChange(e)}
                            onClick={(e) => setRemember(e.target.checked)}
                          />
                          <label htmlFor="cb_remember">Remember Me</label>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div className="signIn_emailTxtBox">
                    <input
                      type="email"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => OnInputChanged("email", e.target.value)}
                    />
                  </div>
                  <ul className="signIn_II_col">
                    <li className="signIn_II_col">
                      <input
                        type="button"
                        value="Login with OTP"
                        className="button_verify"
                        onClick={(e) => OnSignInClicked(e, "OTP")}
                      />
                    </li>
                    <li className="signIn_II_col">
                      <input
                        type="button"
                        value="Login with Password"
                        className="button_verify"
                        onClick={(e) => OnSignInClicked(e, "EMAIL")}
                      />
                    </li>
                  </ul>
                  {received && (
                    <div className="OTP_Pswd_Blk">
                      {scope === "EMAIL" && (
                        <div className="signIn_emailTxtBox">
                          <input
                            onChange={(e) =>
                              OnInputChanged("key", e.target.value)
                            }
                            placeholder="Password"
                            type="password"
                          />
                        </div>
                      )}

                      {scope === "OTP" && (
                        <ul className="signIn_II_col_40by60">
                          <li className="signIn_II_col_40by60">
                            <div className="signIn_emailTxtBox">
                              <input
                                maxLength={6}
                                onKeyDown={(e) => {
                                  setError(null);
                                  return Helper.AcceptedChars(e, "NFINANCE");
                                }}
                                onChange={(e) =>
                                  OnInputChanged("key", e.target.value)
                                }
                                placeholder={scope}
                                type="text"
                              />
                            </div>
                          </li>
                          <li className="signIn_II_col_40by60">
                            <div className="signIn_OTP_note">
                              {scope === "OTP"
                                ? "Please authenticate using the One Time Password that is sent to the email address"
                                : null}
                            </div>
                          </li>
                        </ul>
                      )}
                      <input
                        type="button"
                        value="Sign In"
                        className="button_verify"
                        onClick={(e) => OnVericyClicked(e)}
                      />
                    </div>
                  )}
                  {error && <div className="errormessage_center">{error}</div>}
                </li>
              </ul>
            </div>
            <div className="powered">
              Powered By: <Link to="#">Moham Uttarana Pvt Ltd</Link>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Component;
