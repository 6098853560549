import { React, Link, Helper, Session, useNavigate } from "../../../common";
import { GetOfficersLoans } from "../../../services/loanApi";

const Component = () => {

  const [initlized, setInitlized] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const Navigate = useNavigate();

  const OnLoanClicked = (e, id) => {
    e.preventDefault();
    Session.StoreAsync('floanid', id);
    Navigate("/piped");
  }

  const FetchResults = async () => {
    global.Busy(true);
    setRows([]);
    let rslt = await GetOfficersLoans();
    if (rslt.status === 100) {
      let _data = rslt.data;
      Helper.SortByNumber(_data, '_id', true);
      setRows(_data);
    }
    global.Busy(false);
  };

  if (initlized) {
    setInitlized(false);
    setRows([]);
    Session.RemoveAsync('floanid');
    Session.RemoveAsync('ploanid');
    FetchResults();
  }

  React.useEffect(() => {
    setInitlized(true);
  }, []);


  return (
    <li className="pgIIcol">
      <div className="dashboardShadowBlk">
        <div className="dashboard_headblk">
          <div className="blkName">Pipeline</div>
          <div className="dashboard_head_right">
            <div className="dashboard_rightVerBlock">
              <Link to="/pipe">
                <div className="dashMoreIcon" title="View All Loans"></div>
              </Link>
            </div>
          </div>
        </div>
        {(!rows || rows.length === 0) && (<div className="noContent">No Pipelines are Available</div>)}
        {rows && rows.length > 0 && (
          <div className="dashboardContentBlk">
            <div className="dash_pipelineTable_head">
              <table>
                <tbody>
                  <tr>
                    <td>Loan ID</td>
                    <td>Name</td>
                    <td>Property Details</td>
                    <td>Status</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="dash_pipelineTable">
              <table>
                <tbody>
                  {rows && rows.slice(0, 4).map((x) => (
                    <tr key={x.id}>
                      <td><Link to="#" onClick={(e) => OnLoanClicked(e, x.formid)}>{x.loannumber}</Link></td>
                      <td>{Helper.GetStringJoin(x, ['first_name', 'last_name'])}</td>
                      <td>{x.propaddress}</td>
                      <td>{x?.statusname}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </li>
  );
};

export default Component;
